import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";

import { useCart } from "hooks/useCart";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CartItem } from "./CartItem";
import { CartDto } from "dto/sale/cart.dto";
import { RouteTools } from "tools/utils/route.tool";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CartTotalPrice } from "./CartTotalPrice";
import { Config } from "tools/utils/config";
import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
};
const Cart: React.FC<Props> = ({ anchorEl, open, handleClose }) => {
  const { LL } = useLabel();
  const { cartObjects, cartLength } = useCart();
  const processItem = (item: CartDto, index: number) => {
    if (!item) return null;
    return <CartItem item={item} key={index} handleClose={handleClose} />;
  };

  const handleNavigate = (url: string) => {
    RouteTools.setHistory(url, {});
    handleClose();
  };

  return (
    // <Menu
    //   id="basic-menu-cart"
    //   anchorEl={anchorEl}
    //   open={open}
    //   onClose={handleClose}
    //   variant="menu"
    //   MenuListProps={{
    //     "aria-labelledby": "basic-button-cart",
    //   }}
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "center",
    //   }}
    //   transformOrigin={{
    //     vertical: "top",
    //     horizontal: "center",
    //   }}
    //   className="cart-tmp">
    //   <ListItem className="cartPopUp" key="cart">
    //     <Box className="flexJustifyCenter">
    //       <Typography className="cartTtile">{LL("Cart")}</Typography>
    //     </Box>
    //   </ListItem>
    //   <Box className="dividerBox">
    //     <Divider className="divider" />
    //   </Box>
    //   <Box
    //     className="cartItemBox"
    //     sx={{
    //       alignItems: cartLength ? "flex-start" : "center",
    //     }}>
    //     <List key="cart_items">
    //       {/* <Box key="cart_items"> */}
    //       <Box className="itemsBox">
    //         {cartLength === 0 ? (
    //           <Box className="flexJustifyCenter emptyCart">
    //             <Typography className="flexJustifyCenter emptyCartText">
    //               {LL("emptyCart")}
    //             </Typography>
    //           </Box>
    //         ) : (
    //           cartObjects.map((item, index) => processItem(item, index))
    //         )}
    //       </Box>
    //       {/* </Box> */}
    //     </List>
    //   </Box>
    //   <Box className="cartTotalPrice">
    //     <CartTotalPrice />
    //   </Box>
    //   <Box className="dividerBox">
    //     <Divider className="divider" />
    //   </Box>
    //   <Box className="checkoutButton alignCenter">
    //     <Box className="whiteButton listItemButton authButton standardButtonSize">
    //       <ListItemButton
    //         key="cart_checkout"
    //         onClick={handleNavigate.bind(this, "/checkout")}
    //         className="whiteButton buttonWithLoading flexJustifyCenter">
    //         <Typography className="textButton" sx={{}}>
    //           {LL("Go_to_checkout")}
    //         </Typography>
    //         <Box className="arrowSvg flexJustifyCenter " sx={{}}>
    //           <KeyboardArrowRightIcon className="iconSvg2" />
    //         </Box>
    //       </ListItemButton>
    //     </Box>
    //   </Box>
    // </Menu>

    <Drawer
      anchor="right" 
      open={open}
      onClose={handleClose}
      className="cart-tmp">
      <Box
        sx={{
          width: {
            xs: "auto",
            sm: Config.DRAWER_WIDTH_MAIN,
            height: "100vh",
          },
        }}>
        <ListItem className="cartPopUp" key="cart">
          <Box className="flexJustifyStart ">
            <Typography className="cartTtile">{LL("Cart")}</Typography>
          </Box>
        </ListItem>
        <Box className="drawerCloseButton">
          <IconButton onClick={handleClose}>
            <CloseIcon className="drawerSvg" />
          </IconButton>
        </Box>
        <Box className="dividerBox1">
          <Divider className="divider" />
        </Box>
        <Box
          className="cartItemBox"
          sx={{
            alignItems: cartLength ? "flex-start" : "center",
          }}>
          <List key="cart_items">
            <Box className="itemsBox">
              {cartLength === 0 ? (
                <Box className="flexJustifyCenter emptyCart">
                  <Typography className="flexJustifyCenter emptyCartText">
                    {LL("emptyCart")}
                  </Typography>
                </Box>
              ) : (
                cartObjects.map((item, index) => processItem(item, index))
              )}
            </Box>
          </List>
        </Box>
        <Box className="cartTotalPrice">
          <CartTotalPrice />
        </Box>

        <Box className="dividerBox1">
          <Divider className="divider" />
        </Box>

        <Box className="checkoutButton alignCenter">
          <Box className="whiteButton listItemButton authButton standardButtonSize">
            <ListItemButton
              key="cart_checkout"
              onClick={handleNavigate.bind(this, "/checkout")}
              className="whiteButton buttonWithLoading flexJustifyCenter">
              <Typography className="textButton">
                {LL("Go_to_checkout")}
              </Typography>
              <Box className="arrowSvg flexJustifyCenter " sx={{}}>
                <KeyboardArrowRightIcon className="iconSvg2" />
              </Box>
            </ListItemButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export { Cart };
