import {
  ListItem,
  IconButton,
  ListItemButton,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import { useCountry } from "hooks/useCountry";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";
import { Image } from "components/elements/display/Image";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { InputQuantity } from "components/elements/form/InputQuantity";
import { ProductFilterList } from "components/product/product/checkout/ProductFilterList";
import { useResponsive } from "hooks/useResponsive";
import Grid from "@mui/material/Unstable_Grid2";

type Props = {
  item: CartDto;
};
const CartItem: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();
  const { currencyCode, currencyRate } = useCountry();
  const { removeFromCart, setQuantityCart } = useCart();
  const { downSM, downMD, downMD900 } = useResponsive();

  const handleClick = () => {
    const url = CommonTools.processObjectField(item, [
      "product",
      "_urlObj",
      "urlfull",
    ]);
    if (!url) return;

    RouteTools.setHistory(url, {});
  };

  const handleRemove = () => {
    if (!item) return;
    removeFromCart(item);
  };

  if (!item) return null;
  const total = CartDto.calculateTotalPriceProduct(
    item.real_price,
    item.quantity,
    currencyRate
  );

  const setQuantity = (value: number | string) => {
    if (!item) return;
    setQuantityCart(value, item);
  };
  return (
    <ListItem
      className="cartItemCheckout"
      secondaryAction={
        <Box className="cartItemCheckoutBox">
          <Box className="cartItemBoxCheckout">
            <Box className="dividerBox">
              <Divider className="divider" />
            </Box>
          </Box>
          <Box className="deleteBox">
            <IconButton onClick={handleRemove}>
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Box>
      }>
      <ListItemButton className="cartItem" onClick={handleClick} disableRipple>
        <Box className="imageBoxCartCheckOut">
          <Image
            url={CommonTools.processObjectField(item, [
              "product",
              "_galleryDefault",
              "cdnurl",
            ])}
            withLinK={false}
            sizeInPx={{
              width: downSM ? 286 : downMD900 ? 150 : 200,
              height: downSM ? 286 : downMD900 ? 150 : 200,
            }}
          />
        </Box>
        <Grid container xs={12} className="infoBoxCartCheckout">
          <Grid xs={12} sm={5}>
            <Typography className="infoTextCart">
              {CommonTools.processObjectField(item, ["product", "_name"])}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={4}
            className="quantityBox"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Typography sx={{ display: { xs: "block", sm: "none" } }}>
              {LL("Quantity")}
            </Typography>
            <Box sx={{ maxWidth: "130px" }}>
              <InputQuantity value={item.quantity} setValue={setQuantity} />
            </Box>
          </Grid>
          <Grid xs={12} sm={3} className="priceBox">
            <Box>
              <Typography sx={{ display: { xs: "block", sm: "none" } }}>
                {LL("Price")}
              </Typography>
            </Box>
            <Box className="priceText">
              <Typography className="priceTextStyle">{total}</Typography>
              <Typography className="priceTextStyle">{currencyCode}</Typography>
            </Box>
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
};

export { CartItem };
