import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box, Container, Typography } from "@mui/material";
import { useUser } from "hooks/useUser";
import { LoginForm } from "components/authentication/LoginForm";

import { AdvertisementLoginPage } from "components/static/advertisement/AdvertisementLoginPage";
import { useSeoInfo } from "hooks/useSeoInfo";
import { GoogleLoginButton } from "components/authentication/GoogleLoginButton";
import { useLabel } from "hooks/useLabel";

const LoginPage: React.FC<IPage> = () => {
  const { login } = useUser();
  const { LL } = useLabel();

  useSeoInfo("login");
  return (
    <Box className="authMaineBox">
      <Container maxWidth="sm" className="authContainer">
        <Box className="authBox">
          <LoginForm onSubmit={login} />
          <Typography className="text-strike" sx={{ width: "100%" }} my={2}>
            {LL("orLogin")}
          </Typography>
          <Box sx={{ width: "100%", maxWidth: "400px" }}>
            <GoogleLoginButton />
          </Box>
        </Box>
      </Container>
      <AdvertisementLoginPage />
    </Box>
  );
};

export { LoginPage };
