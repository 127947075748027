import { DictionaryDTO } from "dto/app/dictionary.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import React from "react";
import { GeneralSelect } from "./GeneralSelect";

type Props = {
  field: string;
  label: string;
  setObjectField: (field: string, key: number, value: any) => void;
  arrConfig?: Array<DictionaryDTO>;
  options: Array<SelectOptions>;
  keyConfig: number;
  selectTheme?: any;
};

const ConfigSelect: React.FC<Props> = ({
  field,
  label,
  setObjectField,
  arrConfig,
  options,
  keyConfig,
  selectTheme,
}) => {
  const selectValue = (value: any) => {
    setObjectField(field, keyConfig, value);
  };
  const processValue = () => {
    if (arrConfig) {
      const index = arrConfig.findIndex((x) => x.key === keyConfig);
      if (index !== -1) {
        return arrConfig[index].value;
      }
    }
    return "";
  };

  return (
    <GeneralSelect
      options={options}
      setValue={selectValue}
      value={processValue()}
      _label={label}
      showLabel={true}
      selectTheme={"standardText"}
      variant="outlined"
      size="medium"
      className={selectTheme}
    />
  );
};

export { ConfigSelect };
