import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class OrderRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("order");
  }

  checkout = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/checkout`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  getByUUID = async (uuid: string, cb?: any, cbParams?: any) => {
    const url = `${this.mainUrl}/getbyuuid/${uuid}`;
    return await this.getObject(url, cb, cbParams);
  };

  getPayDetail = async (url: string, cb?: any, cbParams?: any) => {
    return await this.getObject(url, cb, cbParams);
  };

  getPaypalReturn = async (
    value: string,
    cb?: any,
    cbParams?: any
  ) => {
    const url = `${value}`;
    return await this.getObject(url, cb, cbParams);
  };

  getPaypalCancel = async (
    value: string,
    cb?: any,
    cbParams?: any
  ) => {
    const url = `${value}`;
    return await this.getObject(url, cb, cbParams);
  };
}
