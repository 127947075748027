import React from "react";
import { IPage } from "interfaces/page.interface";
import { OrderService } from "services/sale/order.service";
import useObject from "hooks/useObject";
import { CommonTools } from "tools/utils/common.tool";

import { OrderData } from "components/sale/order/OrderData";

const service = new OrderService();
const PaypalReturnPage: React.FC<IPage> = ({ currentRoute }) => {
  const url = CommonTools.processObjectField(currentRoute, ["url"]);

  const getObject = (url: string, cb?: any, cbParams?: any) => {
    if (currentRoute?._mainobject !== "paypalreturn") return;
    if (!url) return;
    service.getPaypalReturn(url, cb, cbParams);
  };

  const [loading, object] = useObject(getObject, url, [url]);

  if (!url) return null;
  return <OrderData loading={loading} object={object} />;
};

export { PaypalReturnPage };
