import React from "react";

import { ProductDto } from "dto/product/product.dto";
import { Box, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { OfferBlock } from "./OfferBlock";
import { ProductFilterItemDictionar } from "components/product/productfilter/ProductFilterItemDictionar";
import { useLabel } from "hooks/useLabel";
import { ButtonWishlist } from "components/product/productwishlist/ButtonWishlist";

type Props = {
  item: ProductDto;
};

const ProductShortInfo: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();
  return (
    <Box className="shortInfoBoxStyle" sx={{ mt: 2 }}>
      <Box>
        <Typography className="productShortInfoCode">
          {CommonTools.processObjectField(item, ["refcode"])}
        </Typography>
      </Box>
      <Box className="productTitleBox">
        <Typography className="productShortInfoTitle">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
        <ButtonWishlist
          idProduct={CommonTools.processObjectField(item, ["id"])}
        />
      </Box>
      <OfferBlock item={item} />
      {/* <Box mt={2}>
        <Typography className="productShortInfoShortInfo">
          {CommonTools.processObjectField(item, ["_shortdescription"])}
        </Typography>
      </Box> */}
    </Box>
  );
};
export { ProductShortInfo };
