import { logger } from "./logger";
import queryString from "query-string";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestPopulateDTO from "dto/app/requestpopulate.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { Config } from "./config";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";
import { SystemService } from "services/system/system.service";
const service = new SystemService();
class RouteTools {
  static _LL: any = false;
  static hasAccess: any = false;
  static checkUser: any = false;

  static setLL(_l: any): any {
    RouteTools._LL = _l;
  }

  static setHasAccess(_h: any): any {
    RouteTools.hasAccess = _h;
  }

  static setCheckUser(_h: any): any {
    RouteTools.checkUser = _h;
  }

  // -----------------------------------------

  static processSpecial(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m === "login") {
      return { mobj: "login", permissionhash: "post-auth-signin" };
    }

    if (m === "signup") {
      return { mobj: "signup", permissionhash: "post-auth-signup" };
    }

    if (m === "forgotpassword") {
      return { mobj: "forgotpassword", permissionhash: "post-forgotpassword" };
    }

    if (m === "resetpassword") {
      return { mobj: "resetpassword", permissionhash: "post-resetpassword" };
    }

    if (m === "forbidden") {
      return { mobj: "forbidden", permissionhash: "" };
    }
  }

  static processUser(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m === "profile") {
      return { mobj: "profile", permissionhash: "get-profile" };
    }
  }

  static processProduct(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m === "categoryproduct") {
      return { mobj: "categoryproduct", permissionhash: "get-categoryproduct" };
    }

    if (m === "product") {
      return { mobj: "product", permissionhash: "get-product" };
    }

    if (m === "search") {
      return { mobj: "search", permissionhash: "get-product" };
    }
  }

  static processPage(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m !== "page") {
      return false;
    }

    if (paths.length < 3) {
      return false;
    }

    const idobj = paths[2] ?? "";

    if (!idobj) {
      return false;
    }

    return { mobj: "page", permissionhash: "get-page", idobj: idobj };
  }

  static processBlog(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m !== "blog") {
      return false;
    }

    if (paths.length < 3) {
      return false;
    }

    const idobj = paths[2] ?? "";

    if (!idobj) {
      return false;
    }

    return { mobj: "blog", permissionhash: "get-blog", idobj: idobj };
  }

  static processBlogCategory(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m !== "categoryblog") {
      return false;
    }

    return { mobj: "categoryblog", permissionhash: "get-blogList" };
  }

  static processSaleModule(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    switch (m) {
      case "checkout": {
        return { mobj: "checkout", permissionhash: "get-checkout" };
      }
      case "orderinfo": {
        if (paths.length < 2) return false;
        return { mobj: "orderinfo", permissionhash: "get-order" };
      }
      case "paypal": {
        if (paths.length < 2) return false;
        switch (paths[1]) {
          case "return": {
            return { mobj: "paypalreturn", permissionhash: "get-paypal-return" };
          }
          case "cancel": {
            return { mobj: "paypalcancel", permissionhash: "get-paypal-cancel" };
          }
          default:
            return false;
        }
      }
      default:
        return false;
    }
  }

  static async processUserFriendly(paths: any, fullUrl: any): Promise<any> {
    const finded = await service.getBySlug(fullUrl);

    if (finded == undefined || finded == null || finded == false || !finded) {
      return false;
    }

    if (finded.obj == undefined || finded.obj == null) {
      return false;
    }

    if (finded.obj.parent === "page") {
      return {
        mobj: "page",
        permissionhash: "get-page",
        idobj: finded.obj.id_parent,
        _obj: finded.obj.targetObj,
      };
    }

    if (finded.obj.parent === "blog") {
      return {
        mobj: "blog",
        permissionhash: "get-blog",
        idobj: finded.obj.id_parent,
        _obj: finded.obj.targetObj,
      };
    }

    if (finded.obj.parent === "categoryproduct") {
      return {
        mobj: "categoryproduct",
        permissionhash: "get-product",
        idobj: finded.obj.id_parent,
        _obj: finded.obj.targetObj,
      };
    }

    if (finded.obj.parent === "categoryblog") {
      return {
        mobj: "categoryblog",
        permissionhash: "get-blog",
        idobj: finded.obj.id_parent,
        _obj: finded.obj.targetObj,
      };
    }

    return false;
  }

  static async processRoute(
    paths: any,
    parsed: any,
    fullUrl: any,
    anchor: string
  ): Promise<any> {
    var mo: any = false;

    mo = RouteTools.processSpecial(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processProduct(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processPage(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processBlog(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processBlogCategory(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processUser(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processSaleModule(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );
    // last check in list
    mo = await RouteTools.processUserFriendly(paths, fullUrl);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    return RouteTools.processRouteVars(
      "notfound",
      paths,
      parsed,
      fullUrl,
      anchor,
      "/"
    );
  }

  static processRouteVars(
    mainObj: any,
    paths: any,
    parsed: any,
    fullUrl: any,
    anchor: string,
    permissionhash: any,
    processedData?: any
  ): ICurrentRoute {
    if (!anchor) anchor = "";
    if (!RouteTools.hasAccess(permissionhash)) {
      RouteTools.setHistory("/forbidden", {});
      mainObj = "forbidden";
    }

    if (
      (mainObj === "login" || mainObj === "signup") &&
      RouteTools.checkUser()
    ) {
      RouteTools.setHistory("/", {});
      mainObj = "homepage";
    }

    if (mainObj === "profile" && !RouteTools.checkUser()) {
      RouteTools.setHistory("/login", {});
      mainObj = "login";
    }

    return {
      _mainobject: mainObj,
      _paths: paths,
      _getVars: parsed,
      url: fullUrl,
      processedData: processedData,
      anchor: anchor,
    };
  }
  // -----------------------------------------
  static prepareListRequest(currentRoute: any, cookies: any): RequestListDTO {
    const cOnPage =
      cookies.onPage != undefined ? Number(cookies.onPage) : Config.ON_PAGE;

    const obj = new RequestListDTO();
    const _getVars =
      currentRoute && currentRoute._getVars ? currentRoute._getVars : {};

    obj.page = _getVars.page ? Number(_getVars.page) : 1;
    obj.onpage = _getVars.onpage ? Number(_getVars.onpage) : cOnPage;

    obj.filters = [];
    if (_getVars.filters != null && _getVars.filters != undefined) {
      const filters = _getVars.filters.split("|");

      for (let i in filters) {
        const t = filters[i].split(",");

        const _o = new RequestFilterDTO();
        _o.field = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.field) continue;
        if (_o.values == undefined || !_o.values.length) continue;

        obj.filters.push(_o);
      }
    }

    if (_getVars.populates != null && _getVars.populates != undefined) {
      const _o = new RequestPopulateDTO();
      _o.populates = _getVars.populates.split(",");
      obj.populate = _o;
    }

    obj.sortcriteria = [];

    if (_getVars.order != null && _getVars.order != undefined) {
      const order = _getVars.order.split("|");

      for (let i in order) {
        const t = order[i].split(",");

        const _o = new RequestSortCriteriaDTO();
        _o.field = t[0].trim();
        _o.asc = t.length > 1 && t[1] == "asc" ? true : false;

        if (!_o.field) continue;

        obj.sortcriteria.push(_o);
      }
    }

    obj.criteria = [];
    if (_getVars.criteria != null && _getVars.criteria != undefined) {
      const criteria = _getVars.criteria.split("|");

      for (let i in criteria) {
        const t = criteria[i].split(",");

        const _o = new RequestCriteriaDTO("", []);
        _o.id = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.id) continue;
        if (_o.values == undefined || !_o.values.length) continue;

        obj.criteria.push(_o);
      }
    }

    obj.range = [];
    if (_getVars.range != null && _getVars.range != undefined) {
      const range = _getVars.range.split("|");

      for (let i in range) {
        const t = range[i].split(",");

        const _o = new RequestCriteriaDTO("", []);
        _o.id = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.id) continue;
        if (_o.values == undefined || !_o.values.length) continue;

        obj.range.push(_o);
      }
    }

    return obj;
  }

  // -----------------------------------------
  static prepareListLocation(obj: RequestListDTO): string {
    let str = "";

    if (obj.page) {
      str += str ? "&" : "?";
      str += "page=" + obj.page.toString();
    }

    if (obj.onpage) {
      str += str ? "&" : "?";
      str += "onpage=" + obj.onpage.toString();
    }

    if (obj.filters && obj.filters.length) {
      let fstr = "";

      for (let i in obj.filters) {
        if (!obj.filters[i].values) continue;
        if (!Array.isArray(obj.filters[i].values)) continue;
        if (!obj.filters[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.filters[i]?.field;
        fstr += ",";
        fstr += obj.filters[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "filters=" + fstr;
      }
    }

    if (obj.sortcriteria && obj.sortcriteria.length) {
      let fstr = "";

      for (let i in obj.sortcriteria) {
        fstr += fstr ? "|" : "";
        fstr += obj.sortcriteria[i]?.field;
        fstr += ",";
        fstr += obj.sortcriteria[i]?.asc ? "asc" : "desc";
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "order=" + fstr;
      }
    }

    if (
      obj.populate &&
      obj.populate.populates &&
      obj.populate.populates.length
    ) {
      str += str ? "&" : "?";
      str += "populates=" + obj.populate.populates.join(",");
    }

    if (obj.criteria && obj.criteria.length) {
      let fstr = "";

      for (let i in obj.criteria) {
        if (!obj.criteria[i].values) continue;
        if (!Array.isArray(obj.criteria[i].values)) continue;
        if (!obj.criteria[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.criteria[i]?.id;
        fstr += ",";
        fstr += obj.criteria[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "criteria=" + fstr;
      }
    }

    if (obj.range && obj.range.length) {
      let fstr = "";

      for (const i in obj.range) {
        if (!obj.range[i].values) continue;
        if (!Array.isArray(obj.range[i].values)) continue;
        if (!obj.range[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.range[i]?.id;
        fstr += ",";
        fstr += obj.range[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "range=" + fstr;
      }
    }

    return str;
  }

  static parseLocation(location: any): Promise<any> {
    const parsed = queryString.parse(location.search);

    const fullUrl = location.pathname + location.search;

    var tpaths = location.pathname.split("/");
    tpaths = tpaths.filter(Boolean);

    let anchor = location.hash.replace("#", "");
    if (!anchor) anchor = "";

    return RouteTools.processRoute(tpaths, parsed, fullUrl, anchor);
  }

  // -----------------------------------------
  static setHistory(location: any, state: any, anchor?: string): any {
    state = state ? state : {};
    if (anchor) location += "#" + anchor;
    window.history.pushState(state, "", location);

    var popStateEvent = new PopStateEvent("popstate", state);
    dispatchEvent(popStateEvent);
  }

  static setAnchor(anchor: string): void {
    window.location.hash = anchor;
  }
  // -----------------------------------------
}

export { RouteTools };
