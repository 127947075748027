import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { OrderDto, PayInfoDto } from "dto/sale/order.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { PayMethodType } from "tools/types/paymethod";
import { Status } from "tools/types/status";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { OrderService } from "services/sale/order.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: OrderDto;
};
const service = new OrderService();
const PayAction: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  const handlePay = () => {
    const url = CommonTools.processObjectField(object, ["payurl"]);
    if (!url) return;
    service.getPayDetail(url, handleResult, {});
  };

  const handleResult = (result: ResultObjectDTO<PayInfoDto>) => {
    if (!result) return;
    if (result.error) return;
    const linkApprove = CommonTools.processObjectField(result, [
      "obj",
      "link_approve",
    ]);
    if (!linkApprove) return;
    window.location.href = linkApprove;
  };
  if (!object) return null;
  if (object.paystatus !== Status.ORD_P_UNPAID) return null;
  if (object.paymethod === PayMethodType.PAYPAL) {
    return (
      <Box
        sx={{ width: { xs: "100%", md: "200px" }, height: "45px" }}
        className="whiteButton listItemButton"
      >
        <MyButton
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
          cb={handlePay}
        >
          <Typography sx={{ mt: "2px", ml: 2, fontWeight: "600" }}>
            {LL("paywithpaypal")}
          </Typography>
          <Box
            sx={{
              width: "20px",
              mt: "3px",
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <KeyboardArrowRightIcon className="iconSvg3" />
          </Box>
        </MyButton>
      </Box>
    );
  }
  return null;
};

export { PayAction };
