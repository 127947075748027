import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { SignupDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useCallback, useEffect, useState } from "react";
import RequiredValidator from "validators/required.validator";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PasswordField } from "components/elements/form/PasswordField";
import { PasswordStrengthChecker } from "./PasswordStrengthChecker";
import { MyTextFieldConfig } from "components/elements/form/MyTextFieldConfig";
import { UserConfigType } from "tools/types/userconfigtype";

import { BirthDateField } from "components/user/profile/BirthDateField";

type Props = {
  onSubmit: (obj: SignupDto, cb: CallbackType) => void;
};
const SignUpForm: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);
  const [obj, disabled, setObjField, setObjConfig, , setIsDisabled] =
    useForm<SignupDto>(
      new SignupDto(),
      RequiredValidator.getValidators(["email", "password"])
    );

  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const handleConfirmPassword = (field: string, value: string) => {
    setConfirmPassword(value);
  };

  const checkPassword = useCallback(() => {
    let disabled = false;
    if (obj.password !== confirmPassword) {
      disabled = true;
    }
    setIsDisabled(disabled);
  }, [confirmPassword, obj.password]);

  useEffect(() => {
    checkPassword();
  }, [checkPassword]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
  };

  return (
    <Box className="authForm">
      <Box className="flexJustifyCenter">
        <Typography className="fontTitle">{LL("signup_title")}</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3} className="flexJustifyCenter">
          <MyTextField
            field="email"
            label={LL("field_email")}
            setObjectField={setObjField}
            value={obj.email}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box mt={3} className="flexJustifyCenter">
          <PasswordField
            field="password"
            label={LL("field_password")}
            setObjectField={setObjField}
            value={obj.password}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box mt={3} className="flexJustifyCenter">
          <PasswordStrengthChecker password={obj.password} />
        </Box>
        <Box mt={3} className="flexJustifyCenter">
          <PasswordField
            field="password"
            label={LL("field_confirm_password")}
            setObjectField={handleConfirmPassword}
            value={confirmPassword}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box mt={3} className="flexJustifyCenter">
          <MyTextFieldConfig
            field="configs"
            sx={{ width: "100%" }}
            label={LL("field_first_name")}
            setObjectField={setObjConfig}
            keyConfig={UserConfigType.FIRST_NAME}
            arrConfig={obj.configs}
            selectTheme="standardText"
          />
        </Box>
        <Box mt={3} className="flexJustifyCenter">
          <MyTextFieldConfig
            field="configs"
            sx={{ width: "100%" }}
            label={LL("field_second_name")}
            setObjectField={setObjConfig}
            keyConfig={UserConfigType.SECOND_NAME}
            arrConfig={obj.configs}
            selectTheme="standardText"
          />
        </Box>
        <Box mt={3} className="flexJustifyCenter">
          <BirthDateField configs={obj.configs} setObjConfig={setObjConfig} />
        </Box>
        <Box mt={3} className="authButton">
          <Box
            className={`standardButtonSizeLogin whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              className="buttonWithLoading flexJustifyCenter">
              <Typography className="textButton" sx={{}}>
                {LL("btn_signup")}
              </Typography>
              <Box className="arrowSvg flexJustifyCenter ">
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { SignUpForm };
