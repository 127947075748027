import React from "react";
import { Box, Typography, Popover, Dialog } from "@mui/material";

import { useLabel } from "hooks/useLabel";
import { MyButton } from "components/elements/button/MyButton";

import { SearchField } from "./SearchField";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

type Props = {
  //   anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SearchDialog: React.FC<Props> = ({ open, handleClose }) => {
  const { LL } = useLabel();

  return (
    <Dialog
      className="searchDialog"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="search-dialog-title"
      aria-describedby="search-dialog-description"
      PaperProps={
        {
          // style: { borderRadius: 20 },
        }
      }>
      <Box className="searchDialogBox">
        <Box
          className="searchDialogBoxInside"
          sx={
            {
              // display: "flex",
              // justifyContent: "space-between",
              // flexDirection: "row",
              // alignItems: "center",
              // py: 2,
              // gap: 3,
            }
          }>
          <SearchField handleAction={handleClose} />
          {/* <Box sx={{}}>
            <MyButton
              cb={handleClose}
              variant="text"
              disableRipple
              className="myButtonAnimation"
              width={"auto"}
              sx={{
                color: "black",
                height: "auto",
              }}>
              <Typography
                className="textAnimation"
                sx={{ textTransform: "uppercase" }}>
                {LL("bt_cancel")}
              </Typography>
            </MyButton>
          </Box> */}
        </Box>
      </Box>
    </Dialog>
  );
};

export { SearchDialog };
