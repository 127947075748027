import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import ResultListDTO from "dto/app/resultlist.dto";
import { BlogDto } from "dto/static/blog.dto";
import { CategoryBlogDto } from "dto/static/categoryblog.dto";
import { createDataContext } from "hoc/createDataContext";
import { BlogService } from "services/static/blog.service";
import { CategoryBlogService } from "services/static/categoryblog.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

import { RouteTools } from "tools/utils/route.tool";

export type StateResource = {
  categoryBlogObjects: Array<CategoryBlogDto> | null;
  categoryId: string | null;
  selectedCategory: CategoryBlogDto | null;
  blogObjects: Array<BlogDto> | null;
  totalBlogs: number;
  totalPagesBlogs: number;
  pageBlog: number;
  loadingBlog: boolean;
  openDrawer: boolean;
};

export type Actions = {
  getListCategory: () => void;
  selectCategory: (category: CategoryBlogDto | null) => void;
  getBlogList: (
    idCategory: string,
    page: number,
    categoryObjects: Array<CategoryBlogDto> | null
  ) => void;
  setPageBlog: (page: number) => void;
  setIdBlogCategory: (id: string) => void;
  resetSelectedCategory: () => void;
  setOpenDrawer: (open: boolean) => void;
  getSelectedCategory: (id: string) => void;
};

const service = new CategoryBlogService();
const serviceBlog = new BlogService();

const SET_CATEGORY_OBJECTS = "set_category_objects";
const SET_SELECTED_CATEGORY = "set_selected_category";
const SET_BLOG_OBJECTS = "set_blog_objects";
const SET_PAGE = "set_page";
const SET_LOADING_BLOG = "set_loading_blog";
const SET_ID_BLOG_CATEGORY = "set_id_blog_category";
const RESET_SELECTED_CATEGORY = "reset_selected_category";
const SET_OPEN_DRAWER = "set_open_drawer";
const GET_SELECTED_CATEGORY = "get_selected_category";

const resourceReducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case SET_CATEGORY_OBJECTS:
      return { ...state, categoryBlogObjects: action.payload };
    case SET_SELECTED_CATEGORY: {
      let id = "";
      if (action.payload) {
        id = CommonTools.processObjectField(action.payload, ["id"]);
      }
      return {
        ...state,
        selectedCategory: action.payload,
        categoryId: id,
        pageBlog: 1,
        openDrawer: false,
      };
    }
    case SET_BLOG_OBJECTS:
      return {
        ...state,
        blogObjects: action.payload.objects,
        totalBlogs: action.payload.total,
        totalPagesBlogs: action.payload.totalpages,
        loadingBlog: false,
      };
    case SET_PAGE:
      return { ...state, pageBlog: action.payload };
    case SET_LOADING_BLOG:
      return { ...state, loadingBlog: true };
    case SET_ID_BLOG_CATEGORY: {
      return {
        ...state,
        categoryId: action.payload,
      };
    }
    case SET_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: action.payload,
      };
    case GET_SELECTED_CATEGORY: {
      const id = action.payload;
      if (!id) return state;
      if (!state.categoryBlogObjects) return state;
      const category = state.categoryBlogObjects.find(
        (item) => item.id?.toString() === id.toString()
      );
      if (!category) return state;
      return {
        ...state,
        selectedCategory: category,
      };
    }

    case RESET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: null,
        categoryId: "",
        blogObjects: null,
        totalBlogs: -1,
        totalPagesBlogs: -1,
        pageBlog: 1,
        loadingBlog: true,
        openDrawer: false,
      };

    default:
      return state;
  }
};

const resetSelectedCategory = (dispatch: any) => () => {
  dispatch({ type: RESET_SELECTED_CATEGORY });
};
const setIdBlogCategory = (dispatch: any) => (id: string) => {
  dispatch({ type: SET_ID_BLOG_CATEGORY, payload: id });
};

const setPageBlog = (dispatch: any) => (page: number) => {
  dispatch({ type: SET_PAGE, payload: page });
};

const getListCategory = (dispatch: any) => () => {
  const req = new RequestListDTO();
  req.page = 1;
  req.onpage = -1;
  req.filters = [
    RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
  ];
  service.getList(handleGetListCategory, { dispatch }, req);
};

const handleGetListCategory =
  (dispatch: any) => (result: ResultListDTO<CategoryBlogDto>) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects ? result.objects : null;
    dispatch({ type: SET_CATEGORY_OBJECTS, payload: objects });
  };

const selectCategory =
  (dispatch: any) => (category: CategoryBlogDto | null) => {
    dispatch({ type: SET_SELECTED_CATEGORY, payload: category });
  };
const getBlogList =
  (dispatch: any) =>
  (
    idCategory: string,
    page: number,
    categoryObjects: Array<CategoryBlogDto> | null
  ) => {
    if (!categoryObjects) return;
    let code = "";
    if (categoryObjects) {
      const object = categoryObjects.find(
        (item) => item.id?.toString() === idCategory.toString()
      );
      if (object) {
        code = CommonTools.processObjectField(object, ["hierarchicalcode"]);
      }
    }

    const req = new RequestListDTO();
    req.page = page;
    req.onpage = parseInt(
      Config.CC("on_page_category_blog", Config.ON_PAGE.toString())
    )
      ? parseInt(Config.CC("on_page_category_blog", Config.ON_PAGE.toString()))
      : Config.ON_PAGE;
    req.filters = [
      RequestFilterDTO.prepareFilter("hcodes", [code]),
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    req.filters = [
      RequestFilterDTO.prepareFilter("hcodes", [code]),
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    dispatch({ type: SET_LOADING_BLOG });
    const urlPart = RouteTools.prepareListLocation(req);

    RouteTools.setHistory(
      CategoryBlogDto.generateUrl(idCategory) + urlPart,
      {}
    );
    serviceBlog.getList(handleGetBlogList, { dispatch }, req);
  };

const handleGetBlogList =
  (dispatch: any) => (result: ResultListDTO<BlogDto>) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects ? result.objects : [];
    const total = result.total ? result.total : 0;
    const totalpages = result.totalpages ? result.totalpages : 0;

    dispatch({
      type: SET_BLOG_OBJECTS,
      payload: { objects, total, totalpages },
    });
  };

const setOpenDrawer = (dispatch: any) => (open: boolean) => {
  dispatch({ type: SET_OPEN_DRAWER, payload: open });
};

const getSelectedCategory = (dispatch: any) => (id: string) => {
  dispatch({ type: GET_SELECTED_CATEGORY, payload: id });
};
export const { Provider, Context } = createDataContext<StateResource, Actions>(
  resourceReducer,
  {
    getListCategory,
    selectCategory,
    getBlogList,
    setPageBlog,
    setIdBlogCategory,
    resetSelectedCategory,
    setOpenDrawer,
    getSelectedCategory
  },
  {
    categoryBlogObjects: null,
    categoryId: null,
    selectedCategory: null,
    blogObjects: null,
    totalBlogs: -1,
    totalPagesBlogs: -1,
    pageBlog: 1,
    loadingBlog: true,
    openDrawer: false,
  }
);
