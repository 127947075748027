import * as React from "react";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonCategoryProductList: React.FC<SkeletonProps> = ({ lines }) => {
  return (
    <Box>
      <Box className="mainBanner" position="relative">
        <Skeleton variant="rectangular" sx={{ height: "100%" }} />
        <Box
          position="absolute"
          top={0}
          left={0}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Skeleton
            variant="text"
            sx={{
              width: { xs: "300px", md: "500px" },
              fontSize: "30px",
              mt: 4,
            }}
          />

          <Skeleton
            variant="rectangular"
            sx={{
              width: { xs: "150px", md: "200px" },
              height: { xs: "34px", md: "48" },
              mt: 4,
            }}
          />
        </Box>
      </Box>
      <Box
        className="mainBanner"
        position="relative"
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: "row",
        }}>
        <Skeleton variant="rectangular" sx={{ height: "100%" }} />
        <Box
          position="absolute"
          top={0}
          left={0}
          sx={{
            width: { xs: "100%", md: "50%" },
            height: { xs: "50%", md: "100%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Skeleton
            variant="text"
            sx={{
              width: { xs: "260px", md: "610px" },
              fontSize: { xs: "34px", md: "48" },
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: { xs: "150px", md: "200px" },
              height: { xs: "34px", md: "48" },
              mt: 4,
            }}
          />
        </Box>
        <Box
          position="absolute"
          top={0}
          right={0}
          sx={{
            width: { xs: "100%", md: "50%" },
            height: { xs: "50%", md: "100%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Skeleton
            variant="text"
            sx={{
              width: { xs: "260px", md: "610px" },
              fontSize: { xs: "34px", md: "48" },
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: { xs: "150px", md: "200px" },
              height: { xs: "34px", md: "48" },
              mt: 4,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { SkeletonCategoryProductList };
