import { Box, Stack } from "@mui/material";
import { MenuBlock } from "components/footer/MenuBlock";
import { PaymentBlock } from "components/footer/PaymentBlock";
import { RegionBlock } from "components/footer/RegionBlock";
import { SocialBlock } from "components/footer/SocialBlock";
import { CopyRightPage } from "components/static/page/block/CopyRightPage";
import { useResponsive } from "hooks/useResponsive";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer: React.FC<IPage> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { matchesCustom2 } = useResponsive();
  return (
    <Box className="footer">
      <Box className="flexJustifyCenter">
        <Box className="footerBox">
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <MenuBlock />
          </Box>
          <Box sx={{ order: isMobile ? -1 : 0, mt: 3, px: { xs: 0, xl: 5 } }}>
            <RegionBlock />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: matchesCustom2 ? "end" : "start",
              flexDirection: "column",
            }}>
            <Box>
              <SocialBlock />
              <PaymentBlock />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="footerCopyRight">
        <Box className="footerCopyRightBox">
          <CopyRightPage />
        </Box>
      </Box>
    </Box>
  );
};

export { Footer };
