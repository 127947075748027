import React, { createContext, useCallback, useEffect, useState } from "react";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";
import { LocalStorageTools } from "api/localstorage.api";
import { ConfigFileDto } from "dto/system/configfile.dto";
import { ConfigFileService } from "services/system/configfile.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/common.tool";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { ConfigTypes } from "tools/types/configtypes";
import { useHead } from "./HeadProvider";


type Props = {
  CF: (str: string, value?: string) => string;
};
export const ConfigFileContext = createContext<Props>({
  CF: () => "",
});

const getLocalConfigFiles = (): Array<ConfigFileDto> => {
  const configfiles = LocalStorageTools.getObject("configfiles");
  if (!configfiles) return [];
  return configfiles;
};

var configfilesLocalGlobal: any = getLocalConfigFiles();
var configfilesTmp: Array<ConfigFileDto> = [];
var savedConfigFiles: Array<string> = [];

const service = new ConfigFileService();

export const ConfigFileProvider: React.FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hashLocal, setHashLocal] = useState<string>(getLocalHash());

  const [hash, setHash] = useState<string>("");
  const {setFavicon} = useHead();

  const CF = (identifier: string): string => {
    if (configfilesLocalGlobal === false) return "";
    const preparedIdentifier = CommonTools.prepareLabelIdentifier(identifier);

    let value = "";
    let founded = false;

    for (const item of configfilesLocalGlobal) {
      if (item.identifier === preparedIdentifier) {
        founded = true;
        value = CommonTools.processObjectField(item, ["cdnurl"]);
        break;
      }
    }
    if (founded) {
      value = value ?? "";
      return value;
    }

    saveConfig(preparedIdentifier);
    return "";
  };

  const getHash = () => {
    service.getHash(handleGetHash, {});
  };

  const handleGetHash = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.error) return;
    if (!result.obj) return;
    const hash = CommonTools.processObjectField(result, ["obj", "hash"]);
    setHash(hash);
  };

  useEffect(() => {
    getHash();
  }, []);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!hash) loading = true;
    setLoading(loading);
  }, [hash]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const loadConfigFiles = (pageNumber?: number) => {
    pageNumber = pageNumber ?? 1;
    const req = new RequestListDTO();
    req.page = pageNumber;
    req.onpage = 50;
    const filter = new RequestFilterDTO();
    filter.field = "type";
    filter.values = [ConfigTypes.SITE.toString()];
    req.filters = [filter];

    service.getConfigListSite(handleGetList, {}, req);
  };

  const handleGetList = (result: ResultListDTO<ConfigFileDto>) => {
    if (!result) return;
    if (result.error) return;
    if (!result.objects) return;
    if (!result.requestinfo) return;
    if (!result.requestinfo.page) return;
    if (!result.totalpages) return;
    configfilesTmp = [...configfilesTmp, ...result.objects];
    if (result.requestinfo.page < result.totalpages) {
      loadConfigFiles(result.requestinfo.page + 1);
    } else {
      processConfigFiles(configfilesTmp);
    }
  };

  const processConfigFiles = (configfiles: Array<ConfigFileDto>) => {
    configfilesLocalGlobal = configfiles;
    LocalStorageTools.saveObject("configfiles", configfiles);
    LocalStorageTools.saveValue("configfile_hash", hash);
    setHashLocal(hash);
  };

  const saveConfig = (identifier: string) => {
    if (savedConfigFiles.includes(identifier)) return;
    savedConfigFiles.push(identifier);

    if (!Array.isArray(configfilesLocalGlobal)) {
      configfilesLocalGlobal = [ConfigFileDto.getLocal(identifier)];
    } else {
      configfilesLocalGlobal = [
        ...configfilesLocalGlobal,
        ConfigFileDto.getLocal(identifier),
      ];
    }

    service.getConfig(identifier, false, {});
  };

  const checkConfigFileHash = useCallback(() => {
    if (!hash) return;
    if (hash === hashLocal) return;
    else loadConfigFiles();
  }, [hash, hashLocal]);

  useEffect(() => {
    checkConfigFileHash();
  }, [checkConfigFileHash]);

  useEffect(() => {
    setFavicon(CF("favicon"));
  }, []);
  
  const value = {
    CF,
  };

  return loading ? (
    <Loading />
  ) : (
    <ConfigFileContext.Provider value={value}>
      {children}
    </ConfigFileContext.Provider>
  );
};

const getLocalHash = (): string => {
  const hash = LocalStorageTools.getValue("configfile_hash");
  if (!hash) return "";
  return hash;
};
