import React from "react";
import { IPage } from "interfaces/page.interface";

import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useLabel } from "hooks/useLabel";
import { PaymentBlock } from "components/sale/paymentblock/PaymentBlock";
import { CartBlock } from "components/sale/cartblock/CartBlock";
import { CheckoutImage } from "components/sale/paymentblock/CheckoutImage";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { TotalPriceBlock } from "components/sale/cartblock/TotalPriceBlock";

const CheckoutPage: React.FC<IPage> = () => {
  const { LL } = useLabel();

  return (
    <Box className="staticStylePage">
      <Box className="breadcrumbBoxCheckoutPage" />
      <Box className="photoBoxStatic">
        <Box>
          <CheckoutImage />
          <Box className="photoBoxStaticMask" />
        </Box>
      </Box>
      <Box className="productTopBlock">
        <MyBreadcrumbs objects={[new BreadcrumbDto(LL("checkout"))]} />
      </Box>
      <Box className="textBoxRelativeStatic">
        <Typography className="staticPageTitle">{LL("checkout")}</Typography>
      </Box>

      <Box className="paymentSectionCheckoutPage">
        <Box className="paymentBox">
          <Typography className="paymentTitlesText">
            {LL("payemntInfo")}
          </Typography>
          <Box className="dividerBox">
            <Divider className="divider" />
          </Box>
          <Box className="paymentBlock">
            <PaymentBlock />
          </Box>
        </Box>
        <Box
          sx={{ height: "100%", width: "100%" }}
          className="cartItemsCheckoutBox"
        >
          <Typography className="paymentTitlesText">
            {LL("cartItems")}
          </Typography>
          <Box className="dividerBox">
            <Divider className="divider" />
          </Box>
          <Grid container xs={12} className="productTitlesCheck">
            <Grid xs={5} className="paymentTitleBoxCheck">
              <Typography className="paymentTitlesTextCheck">
                {LL("title")}
              </Typography>
            </Grid>
            <Grid xs={4} className="paymentTitleBoxCheck">
              <Typography className="paymentTitlesTextCheck">
                {LL("quantity")}
              </Typography>
            </Grid>
            <Grid xs={3} className="paymentTitleBoxCheck">
              <Typography className="paymentTitlesTextCheck">
                {LL("price")}
              </Typography>
            </Grid>
          </Grid>
          
          <CartBlock />
          <Box className="dividerBox">
            <Divider className="divider" />
          </Box>
          <TotalPriceBlock />
        </Box>
      </Box>
    </Box>
  );
};

export { CheckoutPage };
