import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { ProductDto } from "dto/product/product.dto";
import { useCart } from "hooks/useCart";
import { useLabel } from "hooks/useLabel";
import { useProduct } from "hooks/useProduct";
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  object: ProductDto;
  onButtonClick?: () => void;
};

const ButtonAddToCart: React.FC<Props> = ({ object, onButtonClick }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);
  const { addFromCartProductPage } = useCart();
  const { selectedOffer, quantity, selectedFilters } = useProduct();
  const onClick = () => {
    if (onButtonClick) {
      onButtonClick();
    }
    if (!object || !quantity || !selectedOffer) return;
    let quantitySelected = 0;
    if (typeof quantity === "string") quantitySelected = parseInt(quantity);
    else quantitySelected = quantity;
    if (!quantitySelected) return;

    addFromCartProductPage(
      object,
      quantitySelected,
      selectedOffer,
      selectedFilters
    );
  };
  if (!object) return null;

  const isDisabled = !selectedOffer;
  return (
    <Box mt={3} className="authButton ">
      <Box
        className={`standardButtonSize whiteButton listItemButton ${
          isDisabled ? "disabled" : ""
        } ${loading ? "loadingBtn" : ""}
            `}>
        <ButtonWithLoading
          type="submit"
          disabled={isDisabled}
          loading={false}
          onClick={onClick}
          maxWidth="none"
          className="buttonWithLoading flexJustifyCenter">
          <Typography className="textButton" sx={{}}>
            {LL("add_to_cart")}
          </Typography>
          <Box className="arrowSvg flexJustifyCenter">
            {!isDisabled && <KeyboardArrowRightIcon className="iconSvg3" />}
          </Box>
        </ButtonWithLoading>
      </Box>
    </Box>
  );
};

export { ButtonAddToCart };
