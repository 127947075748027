import { Box, Drawer, Stack, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { BlogList } from "components/static/blog/list/BlogList";
import { BlogPagination } from "components/static/blog/list/BlogPagination";
import { BlogCategoryList } from "components/static/blogcategory/BlogCategoryList";
import { BlogCategoryTopBar } from "components/static/blogcategory/BlogCategoryTopBar";
import { useBlogCategory } from "hooks/useBlogCategory";
import { useLabel } from "hooks/useLabel";

import { IPage } from "interfaces/page.interface";
import React, { useEffect, useState } from "react";
import { CommonTools } from "tools/utils/common.tool";

const BlogCategoryPage: React.FC<IPage> = ({ currentRoute }) => {
  let id = CommonTools.processIdFromPaths(currentRoute, 2)
    ? CommonTools.processIdFromPaths(currentRoute, 2)
    : CommonTools.processObjectField(currentRoute, ["processedData", "idobj"])
    ? CommonTools.processObjectField(currentRoute, ["processedData", "idobj"])
    : "";

  const {
    getCategoryList,
    getListBlog,
    getDefaultFilters,
    setIdBlogCategory,
    selectedCategory,
    getCategory,
  } = useBlogCategory();

  const { LL } = useLabel();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getDefaultFilters(currentRoute);
  }, [getDefaultFilters]);

  useEffect(() => {
    setIdBlogCategory(id);
  }, [id]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  useEffect(() => {
    if (!currentRoute) return;
    if (!currentRoute._mainobject) return;
    if (
      CommonTools.processObjectField(currentRoute, ["_mainobject"]) !==
      "categoryblog"
    ) {
      return;
    }
    getListBlog();
  }, [getListBlog]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  return (
    <Box className="blogCategoryStyle">
      <Box className="blogTopBlock">
        <BlogCategoryTopBar />
      </Box>
      <Box className="blogCategoryBox">
        <Box className="blogList">
          <BlogList />
        </Box>
      </Box>
      <Box className="paginationStyle">
        <BlogPagination />
      </Box>
    </Box>
  );
};

export { BlogCategoryPage };
