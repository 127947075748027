import { Context, Actions, StateResource } from "contexts/search.context";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { ICurrentRoute } from "interfaces/currentroute.interface";

import { useMemo, useContext, useCallback } from "react";
import { useCookies } from "react-cookie";
import { Status } from "tools/types/status";
import { RouteTools } from "tools/utils/route.tool";

type Props = () => {
  setSearch: (search: string) => void;
  getProducts: (mainUrl: string, mainObject: string) => void;
  setPageProducts: (page: number) => void;
  setOnPageProducts: (onPage: number) => void;
  setFilters: (filters: RequestFilterDTO[]) => void;
  setSortCriteria: (sortCriteria: RequestSortCriteriaDTO) => void;
  setRequestList: (
    page: number,
    onPage: number,
    filters: RequestFilterDTO[],
    sortCriteria: RequestSortCriteriaDTO
  ) => void;
  getDefaultFilters: (currentRoute: ICurrentRoute | null) => void;
  handleOpenFilterDrawer: () => void;
  closeFilterDrawer: () => void;
  resetState: () => void;
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  replaceCriteriaFilter: (id: string, value: string) => void;
  filterCriteriaLength: number;
  clearFilterCriteria: () => void;
  removeRangeFilterMin: (id: string) => void;
  removeRangeFilterMax: (id: string) => void;
  replaceRangeFilterMin: (id: string, value: string) => void;
  replaceRangeFilterMax: (id: string, value: string) => void;
  handleSearchFilter: () => void;
  handleSetSearch: (search: string) => void;
} & StateResource;

export const useSearch: Props = () => {
  const { state, actions } = useContext(Context);
  const [cookies] = useCookies();
  const {
    search,
    productList,
    totalProducts,
    totalPagesProducts,
    pageProducts,
    onPageProducts,
    filters,
    sortCriteria,
    loadingListProduct,
    openFilterDrawer,
    criteriaFilter,
    rangeFilter,
  } = state;

  const {
    setSearch,
    setFilters,
    setPageProducts,
    setOnPageProducts,
    setSortCriteria,
    getProductList,
    setRequestList,
    handleOpenFilterDrawer,
    closeFilterDrawer,
    resetState,
    addCriteriaFilter,
    setCriteriaFilter,
    removeCriteriaFilter,
    replaceCriteriaFilter,
    removeRangeFilterMin,
    removeRangeFilterMax,
    replaceRangeFilterMin,
    replaceRangeFilterMax,
    setRangeFilter,
    clearFilters,
    handleSetSearch
  } = actions as Actions;

  const filterRangeLength = rangeFilter.length;
  const filterCriteriaLength = criteriaFilter.length + filterRangeLength;

  const getProducts = useCallback(
    (mainUrl: string, mainObject: string) => {
      if (!mainObject) return;
      if (mainObject !== "search") return;
      if (!filters || !filters.length) return;
      getProductList(
        pageProducts,
        onPageProducts,
        filters,
        sortCriteria,
        criteriaFilter,
        mainUrl,
        rangeFilter
      );
    },
    [
      pageProducts,
      onPageProducts,
      filters,
      sortCriteria,
      criteriaFilter,
      rangeFilter,
    ]
  );

  const getDefaultFilters = useCallback(
    (currentRoute: ICurrentRoute | null) => {
      if (!currentRoute) return;
      const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
      if (reqListRoute.page) setPageProducts(reqListRoute.page);
      if (reqListRoute.criteria && reqListRoute.criteria.length > 0)
        setCriteriaFilter(reqListRoute.criteria);
      if (reqListRoute.range && reqListRoute.range.length > 0)
        setRangeFilter(reqListRoute.range);
      if (reqListRoute.filters && reqListRoute.filters.length > 0) {
        setFilters(reqListRoute.filters);
        const searchFilter = reqListRoute.filters.find(
          (filter) => filter.field === "search"
        );
        if (searchFilter) {
          if (searchFilter.values && searchFilter.values.length)
            setSearch(searchFilter.values[0]);
        }
      }

      if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length) {
        setSortCriteria(reqListRoute.sortcriteria[0]);
      }
    },
    []
  );
  const handleSearchFilter = useCallback(() => {
    setFilters([
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      RequestFilterDTO.prepareFilter("search", [search]),
    ]);
  }, [search]);

  const clearFilterCriteria = () => {
    clearFilters();
  };

  return useMemo(
    () => ({
      search,
      productList,
      totalProducts,
      totalPagesProducts,
      pageProducts,
      onPageProducts,
      filters,
      sortCriteria,
      loadingListProduct,
      openFilterDrawer,
      criteriaFilter,
      rangeFilter,
      setSearch,
      setFilters,
      setPageProducts,
      setOnPageProducts,
      setSortCriteria,
      getProducts,
      setRequestList,
      handleOpenFilterDrawer,
      closeFilterDrawer,
      getDefaultFilters,
      clearFilterCriteria,
      resetState,
      addCriteriaFilter,
      removeCriteriaFilter,
      replaceCriteriaFilter,
      removeRangeFilterMin,
      removeRangeFilterMax,
      replaceRangeFilterMin,
      replaceRangeFilterMax,
      filterCriteriaLength,
      handleSearchFilter,
      handleSetSearch
    }),
    [
      search,
      productList,
      totalProducts,
      totalPagesProducts,
      pageProducts,
      onPageProducts,
      filters,
      sortCriteria,
      loadingListProduct,
      openFilterDrawer,
      criteriaFilter,
      rangeFilter,
      getProducts,
      filterCriteriaLength,
      getDefaultFilters,
      handleSearchFilter,
    ]
  );
};
