import { Box, Typography } from "@mui/material";
import { BlogDto } from "dto/static/blog.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: BlogDto;
};

const BlogShortDescription: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  return (
    <Box className="textBoxStaticPage">
      <Box className="textShortDescription">
        <Box sx={{}}>
          <Typography className="fontSizeGeneralShortDescrpt">
            {CommonTools.processObjectField(object, ["_shortdescription"])}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export { BlogShortDescription };
