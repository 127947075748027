import { Box, Stack } from "@mui/material";
import { ConfigSelect } from "components/elements/select/ConfigSelect";
import { DictionaryDTO } from "dto/app/dictionary.dto";
import { UserDto } from "dto/user/user.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { UserConfigType } from "tools/types/userconfigtype";

type Props = {
  setObjConfig: (field: string, key: number, value: any) => void;
  configs?: Array<DictionaryDTO>;
};

const BirthDateField: React.FC<Props> = ({ setObjConfig, configs }) => {
  const { LL } = useLabel();
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={0}
      sx={{
        width: "100%",
      }}>
      <Box
        className="standardTextBirthDay"
        sx={{
          width: "30%",
        }}>
        <ConfigSelect
          field="configs"
          setObjectField={setObjConfig}
          keyConfig={UserConfigType.DAY_B}
          arrConfig={configs}
          label={LL("DD")}
          options={UserDto.getDayOptions()}
          selectTheme={"standardText"}
        />
      </Box>
      <Box
        className="standardTextBirthDay"
        sx={{
          width: "30%",
        }}>
        <ConfigSelect
          field="configs"
          setObjectField={setObjConfig}
          keyConfig={UserConfigType.MONTH_B}
          arrConfig={configs}
          label={LL("MM")}
          options={UserDto.getMonthOptions()}
          selectTheme={"standardText"}
        />
      </Box>
      <Box
        className="standardTextBirthDay"
        sx={{
          width: "30%",
        }}>
        <ConfigSelect
          field="configs"
          setObjectField={setObjConfig}
          keyConfig={UserConfigType.YEAR_B}
          arrConfig={configs}
          label={LL("YYYY")}
          options={UserDto.getYearOptions()}
          selectTheme={"standardText"}
        />
      </Box>
    </Stack>
  );
};

export { BirthDateField };
