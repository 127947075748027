import ResultObjectDTO from "dto/app/resultobject.dto";
import {
  AuthDto,
  ChangePasswordDto,
  LoginSiteDto,
  SignupDto,
  SocialSignInDto,
  UserDto,
} from "dto/user/user.dto";
import { CallbackType } from "interfaces/commontypes.interface";
import { UserContext } from "providers/UserProvider";
import { useContext } from "react";

type ResponseType = {
  signUp: (data: SignupDto, cb?: CallbackType) => void;
  user: UserDto | null;
  logout: () => void;
  login: (data: LoginSiteDto, cb?: CallbackType) => void;
  isAuth: boolean;
  changeProfile: (data: UserDto, cb?: CallbackType) => void;
  changePassword: (data: ChangePasswordDto, cb?: CallbackType) => void;
  handleLogin: (result: ResultObjectDTO<AuthDto>, cbParam?: any) => void;
  signInSocial: (data: SocialSignInDto, cb?: CallbackType) => void;
};
export const useUser = (): ResponseType => {
  const { signUp, user, logout, login, changeProfile, changePassword,handleLogin,signInSocial } =
    useContext(UserContext);
  const isAuth = user !== null;
  return {
    signUp,
    user,
    logout,
    login,
    isAuth,
    changeProfile,
    changePassword,
    handleLogin,
    signInSocial
  };
};
