import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { Image } from "components/elements/display/Image";
import { InputQuantity } from "components/elements/form/InputQuantity";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import { useCountry } from "hooks/useCountry";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

import { RouteTools } from "tools/utils/route.tool";
import { useResponsive } from "hooks/useResponsive";
import { logger } from "tools/utils/logger";

type Props = {
  item: CartDto;
  handleClose: () => void;
};

const CartItem: React.FC<Props> = ({ item, handleClose }) => {
  const { LL } = useLabel();
  const { currencyCode, currencyRate } = useCountry();
  const { removeFromCart, setQuantityCart } = useCart();
  const { downSM } = useResponsive();
  // const { }
  const handleClick = () => {
    const url = CommonTools.processObjectField(item, [
      "product",
      "_urlObj",
      "urlfull",
    ]);
    if (!url) return;
    RouteTools.setHistory(url, {});
    handleClose();
  };

  const handleRemove = () => {
    if (!item) return;
    removeFromCart(item);
  };

  if (!item) return null;
  const total = CartDto.calculateTotalPriceProduct(
    item.real_price,
    item.quantity,
    currencyRate
  );

  const setQuantity = (value: number | string) => {
    if (!item) return;
    setQuantityCart(value, item);
  };

  return (
    <ListItem
      className="cartItem"
      secondaryAction={
        <Box className="flexAlignColumn">
          <Box className="dividerBox">
            <Divider className="divider" />
          </Box>
          <Box>
            <IconButton onClick={handleRemove} sx={{ width: "40px" }}>
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Box>
      }>
      <ListItemButton
        onClick={handleClick}
        className="cartProduct"
        disableRipple>
        <Box className="imageBoxCart">
          <Image
            url={CommonTools.processObjectField(item, [
              "product",
              "_galleryDefault",
              "cdnurl",
            ])}
            withLinK={false}
            sizeInPx={{
              width: downSM ? 286 : 200,
              height: downSM ? 286 : 200,
            }}
          />
        </Box>
        <Box className="infoBoxCart">
          <Box>
            <Box>
              <Typography className="infoTextCart">
                {CommonTools.processObjectField(item, ["product", "_name"])}
              </Typography>
            </Box>
            <Box
              className="quantityBox"
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <Typography>{LL("Quantity")}</Typography>
              <Box sx={{ maxWidth: "130px" }}>
                <InputQuantity value={item.quantity} setValue={setQuantity} />
              </Box>
            </Box>
          </Box>
          <Box className="priceBox">
            <Box>
              <Typography>{LL("Price")}</Typography>
            </Box>
            <Box className="priceText">
              <Typography className="priceTextStyle">{total}</Typography>
              <Typography className="priceTextStyle">{currencyCode}</Typography>
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export { CartItem };
