import { Box, List, ListItemButton, Typography } from "@mui/material";
import { CategoryBlogDto, GroupedCategory } from "dto/static/categoryblog.dto";
import { useBlogCategory } from "hooks/useBlogCategory";
import React, { useCallback, useEffect, useState } from "react";

import { BlogCategoryMainItem } from "./BlogCategoryMainItem";
import { useLabel } from "hooks/useLabel";
import { SkeletonCategoryList } from "components/elements/skeleton/blog/SkeletonCategoryList";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const BlogCategoryList: React.FC = () => {
  const { categoryBlogObjects, selectCategory, categoryId } = useBlogCategory();
  const { LL } = useLabel();

  const [loading, setLoading] = useState<boolean>(true);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!categoryBlogObjects) loading = true;
    setLoading(loading);
  }, [categoryBlogObjects]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processItem = (item: GroupedCategory, index: number) => {
    if (!item) return null;
    return <BlogCategoryMainItem item={item} key={index} />;
  };

  const handleClick = () => {
    selectCategory(null);
  };
  if (loading) return <SkeletonCategoryList />;

  if (!categoryBlogObjects) return null;
  if (!categoryBlogObjects.length) return null;
  const objects = CategoryBlogDto.groupByParent(categoryBlogObjects);
  return (
    <Box className="drawerStyle">
      <List sx={{ mt: { xs: "64px", sm: "50px" } }}>
        <ListItemButton
          className="listItemButton flexJustifyStart fontSizeGeneral listItemButtonBlog"
          key={"all"}
          onClick={handleClick}
          selected={!categoryId}>
          <Typography className="fontSizeGeneral">{LL("all")}</Typography>
          <Box sx={{ mt: "8px" }}>
            <KeyboardArrowRightIcon className="iconSvg" />
          </Box>
        </ListItemButton>
        <Box sx={{ padding: { xs: "8px 0px", sm: "8px 10px" } }}>
          {objects.map((item: GroupedCategory, index: number) =>
            processItem(item, index)
          )}
        </Box>
      </List>
    </Box>
  );
};

export { BlogCategoryList };
