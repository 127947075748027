import { Box, Typography } from "@mui/material";
import { InputQuantity } from "components/elements/form/InputQuantity";
import { useCountry } from "hooks/useCountry";
import { useLabel } from "hooks/useLabel";
import { useProduct } from "hooks/useProduct";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item?: any;
};

const OfferBlock: React.FC<Props> = ({ item }) => {
  const { quantity, setQuantity, selectedOffer } = useProduct();
  const { LL } = useLabel();
  const { currencyRate, currencyCode } = useCountry();

  const processPrice = () => {
    if (!selectedOffer) return LL("out_of_stock");
    const price = CommonTools.processObjectField(selectedOffer, ["real_price"]);
    if (!price) return LL("out_of_stock");
    const finalPrice =
      parseFloat(price) * parseInt(quantity.toString()) * currencyRate;
    if (isNaN(finalPrice) || finalPrice === 0) return LL("out_of_stock");
    return `${finalPrice.toFixed(2)} ${currencyCode}`;
  };
  return (
    <Box className="priceBlockProduct">
      <Box>
        <Typography className="productShortInfoPrice">
          {processPrice()}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography className="productShortInfoShortInfo">
          {CommonTools.processObjectField(item, ["_shortdescription"])}
        </Typography>
      </Box>
      <Box sx={{ width: "150px" }}>
        <Typography className="quantityBoxProduct">
          {LL("quantity")}:
        </Typography>
        <InputQuantity value={quantity} setValue={setQuantity} />
      </Box>
    </Box>
  );
};

export { OfferBlock };
