import { Context, Actions, StateResource } from "contexts/categoryblog.context";
import { CategoryBlogDto } from "dto/static/categoryblog.dto";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import { useMemo, useContext, useCallback } from "react";
import { useCookies } from "react-cookie";
import { RouteTools } from "tools/utils/route.tool";

type Props = () => {
  selectCategory: (category: CategoryBlogDto | null) => void;
  getCategoryList: () => void;
  getListBlog: () => void;
  setPageBlog: (page: number) => void;
  getDefaultFilters: (currentRoute: ICurrentRoute | null) => void;
  setIdBlogCategory: (id: string) => void;
  resetSelectedCategory: () => void;
  setOpenDrawer: (open: boolean) => void;
  getCategory: () => void;
} & StateResource;

export const useBlogCategory: Props = () => {
  const { state, actions } = useContext(Context);
  const [cookies] = useCookies();
  const {
    blogObjects,
    categoryBlogObjects,
    categoryId,
    selectedCategory,
    totalBlogs,
    totalPagesBlogs,
    pageBlog,
    loadingBlog,
    openDrawer,
  } = state;
  const {
    selectCategory,
    getListCategory,
    getBlogList,
    setPageBlog,
    setIdBlogCategory,
    resetSelectedCategory,
    setOpenDrawer,
    getSelectedCategory,
  } = actions as Actions;

  const getCategoryList = useCallback(() => {
    getListCategory();
  }, []);

  const getListBlog = useCallback(() => {
    if (categoryId === null) return;
    if (!categoryBlogObjects) return;

    getBlogList(categoryId, pageBlog, categoryBlogObjects);
  }, [categoryId, pageBlog, categoryBlogObjects]);

  const getDefaultFilters = useCallback(
    (currentRoute: ICurrentRoute | null) => {
      if (!currentRoute) return;
      const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
      if (reqListRoute.page) setPageBlog(reqListRoute.page);
    },
    []
  );

  const getCategory = useCallback(() => {
    if (!categoryId) return;
    if (!categoryBlogObjects) return;
    getSelectedCategory(categoryId);
  }, [categoryId, categoryBlogObjects]);

  return useMemo(
    () => ({
      blogObjects,
      categoryBlogObjects,
      categoryId,
      selectedCategory,
      totalBlogs,
      totalPagesBlogs,
      pageBlog,
      loadingBlog,
      selectCategory,
      getCategoryList,
      getListBlog,
      setPageBlog,
      getDefaultFilters,
      setIdBlogCategory,
      resetSelectedCategory,
      openDrawer,
      setOpenDrawer,
      getCategory
    }),
    [
      blogObjects,
      categoryBlogObjects,
      categoryId,
      selectedCategory,
      totalBlogs,
      totalPagesBlogs,
      pageBlog,
      loadingBlog,
      openDrawer,
      getCategoryList,
      getListBlog,
      getDefaultFilters,
      getCategory
    ]
  );
};
