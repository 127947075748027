import React, { useCallback, useEffect, useState } from "react";

import { LinearProgress, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useLabel } from "hooks/useLabel";

type Props = {
  password: string | undefined;
};
const PasswordStrengthChecker: React.FC<Props> = ({ password }) => {
  const [strength, setStrength] = useState(0);
  const { LL } = useLabel();
  const [label, setLabel] = useState<string>("");
  const [color, setColor] = useState<any>("error");

  const calculateStrength = useCallback(() => {
    let currentStrength = 0;
    if (!password) return currentStrength;
    if (password.length === 0) return currentStrength;
    if (password.length > 8) {
      currentStrength += 10;
    }
    if (password.match(/[a-z]/)) currentStrength += 15;
    if (password.match(/[A-Z]/)) currentStrength += 24;
    if (password.match(/[0-9]/)) currentStrength += 26;
    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) currentStrength += 25;

    return currentStrength;
  }, [password]);

  const processCalculation = useCallback(() => {
    const newStrength = calculateStrength();
    setStrength(newStrength);

    if (newStrength === 100) {
      setLabel("Strong");
      setColor("success");
    }
    if (newStrength >= 50 && newStrength < 100) {
      setLabel("Moderate");
      setColor("warning");
    }
    if (newStrength < 50) {
      setLabel("Weak");
      setColor("error");
    }
  }, [calculateStrength]);

  useEffect(() => {
    processCalculation();
  }, [processCalculation]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={8}>
          <LinearProgress
            variant="determinate"
            value={strength}
            color={color}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary" align="right">
            {LL(label + "_password")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export { PasswordStrengthChecker };
