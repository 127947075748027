import React from "react";
import { IPage } from "interfaces/page.interface";
import { SignUpForm } from "components/authentication/SignUpForm";
import { Box, Container, Typography } from "@mui/material";
import { useUser } from "hooks/useUser";

import { useSeoInfo } from "hooks/useSeoInfo";
import { GoogleLoginButton } from "components/authentication/GoogleLoginButton";
import { useLabel } from "hooks/useLabel";

const SignUpPage: React.FC<IPage> = () => {
  const { signUp } = useUser();
  useSeoInfo("signup");
  const { LL } = useLabel();
  return (
    <Box className="authMaineBox">
      <Container maxWidth="sm" className="authContainer">
        <Box className="authBox">
          <SignUpForm onSubmit={signUp} />
          <Typography className="text-strike" sx={{ width: "100%" }} my={2}>
            {LL("orLogin")}
          </Typography>
          <Box sx={{ width: "100%", maxWidth: "400px" }}>
            <GoogleLoginButton />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export { SignUpPage };
