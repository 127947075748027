import { Box, Typography } from "@mui/material";
import { BlogDto } from "dto/static/blog.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: BlogDto;
  textColor?: string;
};

const BlogTitle: React.FC<Props> = ({ object, textColor }) => {
  if (!object) return null;
  return (
    <Box>
      <Typography className="staticPageTitle">
        {CommonTools.processObjectField(object, ["_name"])}
      </Typography>
    </Box>
  );
};

export { BlogTitle };
