import React, { createContext, useState, useEffect, useRef } from "react";
import { Md5 } from "ts-md5";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";
import { useLanguage } from "./LanguageProvider";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";
import { RouteTools } from "tools/utils/route.tool";


type Props = {
  currentRoute: ICurrentRoute | null;
  setCurrentRoute: (value: ICurrentRoute | null) => void;
  saveCache: (data: any, key: string) => void;
  getCache: (key: string) => any;
  _getListUrl: (defaultUrl?: string) => string;
  _setListUrl: (value: string) => void;
  tabValueUserProfile: string;
  handleChangeTabUserProfile: (
    event: React.SyntheticEvent | undefined,
    value: string
  ) => void;
};
export const ResourceContext = createContext<Props>({
  currentRoute: null,
  setCurrentRoute: () => {},
  saveCache: () => {},
  getCache: () => {},
  _getListUrl: () => "",
  _setListUrl: () => {},
  handleChangeTabUserProfile: () => {},
  tabValueUserProfile: "",
});
const cacheData: any = {};

export const ResourceProvider: React.FC<IProvider> = ({ children }) => {
  const [listUrl, setListUrl] = useState("");
  const [currentRoute, setCurrentRoute] = useState<ICurrentRoute | null>(null);
  const [loading, setLoading] = useState(true);
  const { selectedLanguageCode } = useLanguage();
  const isFirstRender = useRef(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  const getCacheIdentifier = (key: string) => {
    let k = selectedLanguageCode;
    k += "_" + JSON.stringify(key);

    return Md5.hashStr(k);
  };

  const saveCache = (data: any, key: string) => {
    const identifier = getCacheIdentifier(key);

    cacheData[identifier] = data;
  };

  const getCache = (key: string) => {
    const identifier = getCacheIdentifier(key);

    if (cacheData[identifier] !== undefined) return cacheData[identifier];

    return false;
  };

  const _getListUrl = (_default?: string) => {
    _default = _default ?? "";
    let rez = listUrl ?? "";
    rez = rez !== undefined && rez !== "" ? rez : _default;
    return rez;
  };
  const _setListUrl = (value: string) => {
    setListUrl(value);
  };

  const [tabValueUserProfile, setTabValueUserProfile] = useState<string>(
    CommonTools.getAnchor(currentRoute, Config.DEFAULT_TAB)
  );

  const handleChangeTabUserProfile = (
    event: React.SyntheticEvent | undefined,
    newValue: any
  ) => {
    setTabValueUserProfile(newValue);
    RouteTools.setHistory("/profile", {}, newValue);
  };

  useEffect(() => {
    if (!currentRoute) return;
    if (isFirstRender.current) {
      setTabValueUserProfile(
        CommonTools.getAnchor(currentRoute, Config.DEFAULT_TAB)
      );
      isFirstRender.current = false;
    }
  }, [currentRoute]);

  const value = {
    currentRoute,
    setCurrentRoute,
    saveCache,
    getCache,
    _getListUrl,
    _setListUrl,
    tabValueUserProfile,
    handleChangeTabUserProfile,
  };

  return loading ? (
    <Loading />
  ) : (
    <ResourceContext.Provider value={value}>
      {children}
    </ResourceContext.Provider>
  );
};
