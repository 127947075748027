import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { StaticSelect } from "components/elements/select/StaticSelect";
import { CountrySelectId } from "components/static/country/CountrySelectId";
import { AutocompleteLocation } from "components/static/location/AutocompleteLocation";
import { CheckoutDto } from "dto/sale/checkout.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";

import React, { useCallback, useEffect, useState } from "react";
import { ClientType } from "tools/types/clienttype";
import RequiredValidator from "validators/required.validator";
import { SelectPayMethod } from "./SelectPayMethod";
import { CallbackType } from "interfaces/commontypes.interface";
import { Stepper, Step, StepLabel, styled } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { RadioSelectDeliveryMethod } from "./RadioSelectDeliveryMethod";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { useResponsive } from "hooks/useResponsive";

type Props = {
  defaultObject: CheckoutDto;
  onSubmit: (checkout: CheckoutDto, cb: CallbackType) => void;
  loading: boolean;
};

const steps = ["client_info", "delivery_info", "payment_info"];

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  ".MuiStepIcon-root": {
    width: "30px",
    height: "30px",
    // color: theme.palette.primary.main,
  },
  "MuiSvgIcon-root": {
    color: "black",
  },
  ".MuiStepLabel-label": {
    // color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "14px",
  },
}));

const FormCheckout: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const defaultValidation = () => {
    if (
      !obj.paymethod ||
      !obj.destinatar_idcountry ||
      !obj.destinatar_idlocation ||
      !obj.destinatar_address ||
      !obj.client_name ||
      !obj.client_mobil ||
      !obj.client_email ||
      !obj.client_type ||
      !obj.iddeliverymethod ||
      !obj.orderProducts ||
      !obj.orderProducts.length
    )
      return true;

    return false;
  };

  const { matchesCustom, downMD } = useResponsive();

  const customValidator = () => {
    if (obj.client_type === ClientType.INDIVIDUAL) {
      if (
        !obj.client_fct_fiz_name ||
        !obj.client_fct_fiz_idno ||
        !obj.client_fct_fiz_docnumber ||
        !obj.client_fct_fiz_address ||
        !obj.client_fct_fiz_phone ||
        defaultValidation()
      )
        return true;
    } else {
      if (
        !obj.client_fct_jur_company ||
        !obj.client_fct_jur_idno ||
        !obj.client_fct_jur_othercode ||
        !obj.client_fct_jur_address ||
        !obj.client_fct_jur_iban ||
        !obj.client_fct_jur_bank ||
        !obj.client_fct_jur_phone ||
        defaultValidation()
      )
        return true;
    }

    return false;
  };

  const { LL } = useLabel();
  const [obj, disabled, setObjField, , setObject] = useForm<CheckoutDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "paymethod",
      "destinatar_idcountry",
      "destinatar_idlocation",
      "destinatar_address",
      "client_name",
      "client_mobil",
      "client_email",
      "client_type",
      "iddeliverymethod",
    ]),
    customValidator
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const [disabledNextClientInfo, setDisabledNextClientInfo] =
    useState<boolean>(true);
  const [disabledNextDeliveryInfo, setDisabledNextDeliveryInfo] =
    useState<boolean>(true);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveStep(2);
    onSubmit(obj, cb);
  };

  const handleSetCountry = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    t["destinatar_idlocation"] = "";
    t["destinatar_address"] = "";
    if (obj.client_type === ClientType.INDIVIDUAL)
      t["client_fct_fiz_address"] = "";
    t["iddeliverymethod"] = 0;

    setObject(t);
  };

  const checkDisabledClientInfo = useCallback(() => {
    let disabled = false;
    if (!obj.client_name || !obj.client_mobil || !obj.client_email)
      disabled = true;
    setDisabledNextClientInfo(disabled);
  }, [obj.client_name, obj.client_mobil, obj.client_email]);

  const checkDisabledDeliveryInfo = useCallback(() => {
    let disabled = false;
    if (
      !obj.destinatar_idcountry ||
      !obj.destinatar_idlocation ||
      !obj.destinatar_address ||
      !obj.paymethod ||
      !obj.iddeliverymethod
    )
      disabled = true;
    setDisabledNextDeliveryInfo(disabled);
  }, [
    obj.destinatar_idcountry,
    obj.destinatar_idlocation,
    obj.destinatar_address,
    obj.paymethod,
    obj.iddeliverymethod,
  ]);

  useEffect(() => {
    checkDisabledClientInfo();
  }, [checkDisabledClientInfo]);

  useEffect(() => {
    checkDisabledDeliveryInfo();
  }, [checkDisabledDeliveryInfo]);

  const handleSetLocation = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    t["iddeliverymethod"] = 0;
    setObject(t);
  };

  const handelSetClientName = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    if (obj.client_type === ClientType.INDIVIDUAL)
      t["client_fct_fiz_name"] = value;

    setObject(t);
  };

  const handelSetClientMobil = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    if (obj.client_type === ClientType.INDIVIDUAL)
      t["client_fct_fiz_phone"] = value;
    setObject(t);
  };

  const handleSetAddress = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    if (obj.client_type === ClientType.INDIVIDUAL)
      t["client_fct_fiz_address"] = value;
    setObject(t);
  };

  const cb = () => {
    setObject(defaultObject);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const processClientInfo = () => {
    if (activeStep !== 0) return null;
    return (
      <Box className="paymentFormParent">
        <Box className="paymentForm">
          <MyTextField
            field="client_name"
            label={LL("client_name")}
            setObjectField={handelSetClientName}
            value={obj.client_name}
            sx={{ width: "100%" }}
            selectTheme={"standardTextPayment"}
          />
          <MyTextField
            field="client_mobil"
            label={LL("client_mobil")}
            setObjectField={handelSetClientMobil}
            value={obj.client_mobil}
            sx={{ width: "100%" }}
            selectTheme={"standardTextPayment"}
          />
          {/* <MyTextField
            field="client_email"
            label={LL("client_email")}
            setObjectField={setObjField}
            value={obj.client_email}
            sx={{ width: "100%" }}
            selectTheme={"standardTextPayment"}
          /> */}
          <Box>
            <MyTextField
              field="client_email"
              label={LL("client_email")}
              setObjectField={setObjField}
              value={obj.client_email}
              sx={{ width: "100%" }}
              selectTheme={"standardTextPayment"}
            />
          </Box>

          <Box>
            <MyTextField
              field="client_othercontacts"
              label={LL("client_othercontacts")}
              setObjectField={setObjField}
              value={obj.client_othercontacts}
              sx={{ width: "100%" }}
              selectTheme={"standardTextPayment"}
            />
          </Box>
        </Box>
        <Box>
          <MyTextField
            field="coments"
            label={LL("coments")}
            setObjectField={setObjField}
            value={obj.coments}
            multiline
            rows={4}
            sx={{ width: "100%", height: "100%" }}
            selectTheme={"standardTextPayment"}
          />
        </Box>
      </Box>
    );
  };

  const processDeliveryInfo = () => {
    if (activeStep !== 1) return null;
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 4, md: 3 },
            alignItems: "end",
            flexDirection: { xs: "column", md: "row" },
          }}>
          <CountrySelectId
            setObjectField={handleSetCountry}
            value={obj.destinatar_idcountry}
            selectTheme={"whiteSelect"}
          />
          <AutocompleteLocation
            setObjField={handleSetLocation}
            idcountry={obj.destinatar_idcountry}
            value={obj.destinatar_idlocation}
            selectTheme={"whiteSelect"}
          />
        </Box>
        {/* <Box mt={4}>
          <AutocompleteLocation
            setObjField={handleSetLocation}
            idcountry={obj.destinatar_idcountry}
            value={obj.destinatar_idlocation}
          />
        </Box> */}
        <Box mt={4}>
          <MyTextField
            field="destinatar_address"
            label={LL("destinatar_address")}
            setObjectField={handleSetAddress}
            value={obj.destinatar_address}
            sx={{ width: "100%" }}
            selectTheme={"standardTextPayment"}
          />
        </Box>
        <Box mt={5}>
          <SelectPayMethod
            setObjField={setObjField}
            value={obj.paymethod}
            label={LL("paymethod")}
          />
        </Box>
        <Box mt={4}>
          <RadioSelectDeliveryMethod
            setObjectField={setObjField}
            value={obj.iddeliverymethod}
            idlocation={obj.destinatar_idlocation}
          />
        </Box>
        <Box mt={4}>
          <MyTextField
            field="delivery_comments"
            label={LL("delivery_comments")}
            setObjectField={setObjField}
            value={obj.delivery_comments}
            multiline
            rows={4}
            sx={{ width: "100%" }}
            selectTheme={"standardTextPayment"}
          />
        </Box>
      </Box>
    );
  };

  const processPaymentInfo = () => {
    if (activeStep !== 2) return null;
    return (
      <Box>
        <Box mt={4} sx={{ width: "100%" }}>
          <StaticSelect
            setObjField={setObjField}
            value={obj.client_type}
            service={ClientType}
            field="client_type"
            label="client_type"
            selectTheme={"whiteSelect"}
            maxWidth={"100%"}
          />
        </Box>

        {obj.client_type === ClientType.INDIVIDUAL ? (
          <Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_fiz_name"
                label={LL("client_fct_fiz_name")}
                setObjectField={setObjField}
                value={obj.client_fct_fiz_name}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_fiz_idno"
                label={LL("client_fct_fiz_idno")}
                setObjectField={setObjField}
                value={obj.client_fct_fiz_idno}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_fiz_docnumber"
                label={LL("client_fct_fiz_docnumber")}
                setObjectField={setObjField}
                value={obj.client_fct_fiz_docnumber}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_fiz_address"
                label={LL("client_fct_fiz_address")}
                setObjectField={setObjField}
                value={obj.client_fct_fiz_address}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_fiz_phone"
                label={LL("client_fct_fiz_phone")}
                setObjectField={setObjField}
                value={obj.client_fct_fiz_phone}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_jur_company"
                label={LL("client_fct_jur_company")}
                setObjectField={setObjField}
                value={obj.client_fct_jur_company}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_jur_idno"
                label={LL("client_fct_jur_idno")}
                setObjectField={setObjField}
                value={obj.client_fct_jur_idno}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_jur_othercode"
                label={LL("client_fct_jur_othercode")}
                setObjectField={setObjField}
                value={obj.client_fct_jur_othercode}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_jur_address"
                label={LL("client_fct_jur_address")}
                setObjectField={setObjField}
                value={obj.client_fct_jur_address}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_jur_iban"
                label={LL("client_fct_jur_iban")}
                setObjectField={setObjField}
                value={obj.client_fct_jur_iban}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_jur_bank"
                label={LL("client_fct_jur_bank")}
                setObjectField={setObjField}
                value={obj.client_fct_jur_bank}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
            <Box mt={4}>
              <MyTextField
                field="client_fct_jur_phone"
                label={LL("client_fct_jur_phone")}
                setObjectField={setObjField}
                value={obj.client_fct_jur_phone}
                sx={{ width: "100%" }}
                selectTheme={"standardTextPayment"}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const processActions = () => {
    if (activeStep === 0)
      return (
        <Box
          sx={{ mt: 3, width: "100%", display: "flex", justifyContent: "end" }}>
          <Box
            sx={{ width: matchesCustom ? "200px" : "100%", height: "45px" }}
            className={`whiteButton listItemButton ${
              disabledNextClientInfo ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""} `}>
            <MyButton
              onClick={handleNext}
              disabled={disabledNextClientInfo}
              sx={{
                height: "100%",
                // background: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}>
              <Typography
                sx={{ mt: "4px", fontWeight: "600", ml: { xs: 0, sm: 3 } }}>
                {LL("btn_next")}
              </Typography>
              <Box
                sx={{
                  width: "20px",
                  mt: "3px",
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!disabledNextClientInfo && (
                  <KeyboardArrowRightIcon className="iconSvg3" />
                )}
              </Box>
            </MyButton>
          </Box>
        </Box>
      );

    if (activeStep === 1) {
      return (
        <Box
          sx={{
            mt: 3,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
            gap: 4,
          }}>
          <Box className="authButton">
            <Box className="standardButtonSize whiteButton listItemButtonBack">
              <MyButton
                onClick={handleBack}
                className="buttonWithLoading flexJustifyCenter">
                <Box
                  className="arrowSvg flexJustifyCenter "
                  sx={{ ml: "-20px" }}>
                  <KeyboardArrowLeftIcon
                    className="iconSvg3"
                    sx={{ transform: "rotate(180deg)" }}
                  />
                </Box>
                <Typography className="textButtonBack">
                  {LL("btn_back")}
                </Typography>
              </MyButton>
            </Box>
          </Box>
          <Box
            sx={{ width: matchesCustom ? "200px" : "100%", height: "45px" }}
            className={`standardButtonSize whiteButton listItemButton ${
              disabledNextDeliveryInfo ? "disabled" : ""
            }`}>
            <MyButton
              onClick={handleNext}
              disabled={disabledNextDeliveryInfo}
              className="buttonWithLoading flexJustifyCenter">
              <Typography className="textButton">{LL("btn_next")}</Typography>
              <Box className="arrowSvg flexJustifyCenter ">
                {!disabledNextDeliveryInfo && (
                  <KeyboardArrowRightIcon className="iconSvg3" />
                )}
              </Box>
            </MyButton>
          </Box>
        </Box>
      );
    }
    if (activeStep === 2)
      return (
        <Box
          sx={{
            mt: 3,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
            gap: 4,
          }}>
          <Box className="authButton">
            <Box className="standardButtonSize whiteButton listItemButtonBack">
              <MyButton
                onClick={handleBack}
                className="buttonWithLoading flexJustifyCenter">
                <Box
                  className="arrowSvg flexJustifyCenter "
                  sx={{ ml: "-20px" }}>
                  <KeyboardArrowLeftIcon
                    className="iconSvg3"
                    sx={{ transform: "rotate(180deg)" }}
                  />
                </Box>
                <Typography className="textButtonBack">
                  {LL("btn_back")}
                </Typography>
              </MyButton>
            </Box>
          </Box>
          <Box
            className={`standardButtonSize whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              maxWidth="100%"
              className="buttonWithLoading flexJustifyCenter">
              <Typography className="textButton4">
                {LL("btn_submit")}
              </Typography>
              <Box className="arrowSvg flexJustifyCenter">
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      );
  };
  return (
    <Box className="stepperBox">
      <Stepper
        activeStep={activeStep}
        orientation={downMD ? "vertical" : "vertical"}>
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <CustomStepLabel>{LL(label)}</CustomStepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box className="paymentFields">
        <form onSubmit={handleSubmit}>
          {processClientInfo()}
          {processDeliveryInfo()}
          {processPaymentInfo()}
          {processActions()}
        </form>
      </Box>
    </Box>
  );
};

export { FormCheckout };
