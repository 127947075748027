
import { GoogleOAuthProvider as Provider} from "@react-oauth/google";
import IProvider from "interfaces/provider.interface";
import { Config } from "tools/utils/config";

export const GoogleOAuthProvider:React.FC<IProvider> = ({ children }) => {
   
  const clientId: string = Config.GOOGLE_CONSOLE_CLIENT_ID;
  return (
    <Provider clientId={clientId}>{children}</Provider>
  );
};
