import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyTextField } from "components/elements/form/MyTextField";
import { LoginSiteDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PasswordField } from "components/elements/form/PasswordField";
import { NavButton } from "components/elements/button/NavButton";
import { CommonTools } from "tools/utils/common.tool";
type Props = {
  onSubmit: (obj: LoginSiteDto, cb: CallbackType) => void;
};
const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);
  const [obj, disabled, setObjField] = useForm<LoginSiteDto>(
    new LoginSiteDto(),
    RequiredValidator.getValidators(["email", "password"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
  };

  return (
    <Box className="authForm">
      <Box className="flexJustifyCenter">
        <Typography className="fontTitle">{LL("login_title")}</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3} className="flexJustifyCenter">
          <MyTextField
            field="email"
            label={LL("field_email")}
            setObjectField={setObjField}
            value={obj.email}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box mt={3} className="flexJustifyCenter">
          <PasswordField
            field="password"
            label={LL("field_password")}
            setObjectField={setObjField}
            sx={{ width: "100%" }}
            value={obj.password}
            selectTheme={"standardText"}
          />
        </Box>
        <Box mt={3} className="fgtScn">
          <MyCheckboxField
            field="remember"
            label={LL("field_remember_me")}
            setObjectField={setObjField}
            checked={obj.remember}
            className="checkboxBlack"
          />
          <Box className="myButtonAnimation">
            <NavButton
              className={"textAnimation navButtonBlack "}
              href="/forgotpassword"
              _hstate={{
                email: CommonTools.processObjectField(obj, ["email"]),
              }}>
              {LL("btn_forgotpassword")}
            </NavButton>
          </Box>
        </Box>
        <Box mt={3} className="authButton">
          <Box
            className={`standardButtonSizeLogin whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              className="buttonWithLoading flexJustifyCenter">
              <Typography className="textButton" sx={{}}>
                {LL("btn_login")}
              </Typography>
              <Box className="arrowSvg flexJustifyCenter ">
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { LoginForm };
