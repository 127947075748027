import { Box, MenuItem, Typography } from "@mui/material";
import { UserMenuType } from "dto/user/user.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonIcon from "@mui/icons-material/Person";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import FavoriteIcon from "@mui/icons-material/Favorite";

type Props = {
  item: UserMenuType;
};

const UserTopMenuItem: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();

  const handleOnClick = () => {
    if (item.onClick) {
      item.onClick(item.url, item.anchor);
    }
  };

  if (!item) return null;
  return (
    <Box sx={{ px: 3 }}>
      <MenuItem onClick={handleOnClick}>
        {(() => {
          switch (item.id) {
            case "logout":
              return <LogoutIcon />;
            case "login":
              return <LoginIcon />;
            case "signup":
              return <PersonAddIcon />;
            case "profile":
              return <PersonIcon />;
            case "myorder":
              return <LocalMallIcon />;
            case "wishlist":
              return <FavoriteIcon />;
            default:
              return null;
          }
        })()}
        <Typography sx={{ ml: 1 }}>{LL(item.label)}</Typography>
      </MenuItem>
    </Box>
  );
};

export { UserTopMenuItem };
