import React from "react";
import { IPage } from "interfaces/page.interface";
import { OrderService } from "services/sale/order.service";
import useObject from "hooks/useObject";
import { CommonTools } from "tools/utils/common.tool";

import { OrderData } from "components/sale/order/OrderData";

const service = new OrderService();
const OrderInfoPage: React.FC<IPage> = ({ currentRoute }) => {
  const id = CommonTools.processIdFromPaths(currentRoute, 1);

  const getObject = (uuid: string, cb?: any, cbParams?: any) => {
    if (currentRoute?._mainobject !== "orderinfo") return;
    if (!uuid) return;
    service.getByUUID(uuid, cb, cbParams);
  };

  const [loading, object] = useObject(getObject, id, [id]);

  return <OrderData loading={loading} object={object} />;
};

export { OrderInfoPage };
