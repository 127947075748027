import React from "react";

import { NavigatorDto } from "dto/static/navigator.dto";
import { Box, Link, SxProps, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  item: NavigatorDto;
  myStyle?: SxProps;
  linkStyle?: SxProps;
  className?: string;
};

const MenuAnchor: React.FC<Props> = ({
  item,
  myStyle = {},
  linkStyle = {},
  className,
}) => {
  const url = CommonTools.processObjectField(item, ["url"]);
  const anchor = CommonTools.processObjectField(item, ["anchor"]);

  const navigateToSection = () => {
    if (!url) return;
    if (!anchor) return;
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      return true;
    }
    return false;
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!url) return;
    if (window.location.pathname === url) {
      navigateToSection();
    } else {
      RouteTools.setHistory(url, {});
      setTimeout(() => {
        navigateToSection();
      }, 1000);
    }
  };

  if (!item) return null;
  return (
    <Box className={className}>
      <Link href={url} onClick={handleClick} sx={linkStyle}>
        <Typography sx={myStyle} className={"textAnimation"}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Link>
    </Box>
  );
};

export { MenuAnchor };
