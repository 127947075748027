import {
  SignupDto,
  LoginDto,
  UserDto,
  ForgotPasswordDto,
  ResetPasswordDto,
  SocialSignInDto,
} from "dto/user/user.dto";
import { UserRepository } from "../../repositories/user/user.repository";
import { GeneralService } from "../general.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class UserService extends GeneralService<UserRepository> {
  constructor() {
    super(new UserRepository());
  }

  signup = async (
    cb?: any,
    cbparameters?: any,
    data?: SignupDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.signup(this.handleGet, cbparameters, data);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  logout = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.logout(this.handleGet, cbparameters);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  login = async (
    cb?: any,
    cbparameters?: any,
    data?: LoginDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.login(this.handleGet, cbparameters, data);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getUserByToken = async (
    token: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getUserByToken(
      token,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  changeProfile = async (
    cb?: any,
    cbparameters?: any,
    data?: UserDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.changeProfile(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  changePassword = async (
    cb?: any,
    cbparameters?: any,
    data?: UserDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.changePassword(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  forgotPassword = async (
    cb?: any,
    cbparameters?: any,
    data?: ForgotPasswordDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.forgotPassword(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  resetPassword = async (
    cb?: any,
    cbparameters?: any,
    data?: ResetPasswordDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.resetPassword(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  signInSocial = async (
    cb?: any,
    cbparameters?: any,
    data?: SocialSignInDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.signInSocial(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      let sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "email";

      obj.sortcriteria = [sobj];
    }

    return obj;
  }
}
