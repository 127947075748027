import { useGoogleLogin } from "@react-oauth/google";
import { MyButton } from "components/elements/button/MyButton";
import { SocialSignInDto } from "dto/user/user.dto";
import { useLabel } from "hooks/useLabel";
import { useUser } from "hooks/useUser";
import React from "react";
import { SocialIdentifier } from "tools/types/socialidentifier";
import { IconType } from "components/elements/icon/IconContext";
import { MyIcon } from "components/elements/icon/MyIcon";

const GoogleLoginButton: React.FC = () => {
  const { signInSocial } = useUser();
  const { LL } = useLabel();
  const responseMessage = (response?: any) => {
    if (!response) return;
    signInSocial(
      new SocialSignInDto(
        SocialIdentifier.GOOGLE,
        response.credential,
        response
      )
    );
  };

  const errorMessage = (e?: any) => {
    // logger("error Google", e);
  };

  const onClickLogin = useGoogleLogin({
    onError: errorMessage,
    onSuccess: responseMessage,
  });

  // theme={"outline"} // "outline" | "filled_blue" | "filled_black"
  // text={"signin"} // "signin_with" | "signup_with" | "continue_with" | "signin"
  // logo_alignment={"left"} // "left" | "center"
  // size={"small"} // "small" | "large" | "medium"
  // shape={"rectangular"} // "rectangular" | "pill" | "circle" | "square"
  // https://developers.google.com/identity/gsi/web/reference/js-reference
  // locale={"zh_CN"}

  const handleOnClick = () => {
    onClickLogin();
  };
  return (
    <MyButton cb={handleOnClick} sx={{ gap: 1 }} className="googleButton2">
      <MyIcon type={IconType.GoogleIcon} />
      {LL("Login_with_google")}
    </MyButton>
  );
  // return (
  //   <GoogleLogin
  //     onSuccess={responseMessage}
  //     onError={errorMessage}
  //     theme={"outline"}
  //     text={"signin"}
  //     logo_alignment={"left"}
  //     size={"large"}
  //     containerProps={{
  //       // className: "googleButton",
  //       className: "googleButton1",
  //     }}
  //   />
  // );
};

export { GoogleLoginButton };
