import { Box, ListItemButton, Typography } from "@mui/material";
import { CategoryBlogDto } from "dto/static/categoryblog.dto";
import { useBlogCategory } from "hooks/useBlogCategory";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  item: CategoryBlogDto;
};

const BlogCategoryItem: React.FC<Props> = ({ item }) => {
  const { selectCategory, categoryId } = useBlogCategory();

  const handleClick = () => {
    if (!item) return;
    selectCategory(item);
  };

  if (!item) return null;
  const isSelected = categoryId == CommonTools.processObjectField(item, ["id"]);

  return (
    <ListItemButton
      onClick={handleClick}
      selected={isSelected}
      className="listItemButton flexJustifyStart ">
      <Typography className="fontSizeGeneral">
        {CommonTools.processObjectField(item, ["_name"])}
      </Typography>
      <Box sx={{ mt: "4px" }}>
        <KeyboardArrowRightIcon className="iconSvg" />
      </Box>
    </ListItemButton>
  );
};

export { BlogCategoryItem };
