import { Box, Drawer, Stack, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useBlogCategory } from "hooks/useBlogCategory";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { BlogCategoryList } from "./BlogCategoryList";
import { Config } from "tools/utils/config";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BlogCategoryTopBar: React.FC = () => {
  const { selectedCategory, openDrawer, setOpenDrawer } = useBlogCategory();

  const { LL } = useLabel();

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
        <Box>
          <Typography className="filterDialogButtonText fontSizeGeneral ">
            {LL("category")}
          </Typography>
        </Box>
        <Box>
          <MyButton
            className="filterDialogButton flexJustifyCenter productPageMoreInfo myButtonAnimation  "
            cb={handleOpen}>
            <Box className="flexJustifyCenter textAnimation">
              {!selectedCategory ? (
                <Typography className="filterDialogButtonText fontSizeGeneral ">
                  {LL("all")}
                </Typography>
              ) : (
                <Typography className="filterDialogButtonText fontSizeGeneral ">
                  {CommonTools.processObjectField(selectedCategory, ["_name"])}
                </Typography>
              )}
            </Box>
          </MyButton>
        </Box>
      </Box>
      <Drawer
        open={openDrawer}
        onClose={handleClose}
        anchor={Config.DRAWER_ANCHOR}>
        <Box
          sx={{
            width: {
              xs: "auto",
              sm: Config.DRAWER_WIDTH_MAIN,
              height: "100vh",
            },
          }}>
          <Box className="drawerCloseButton">
            <IconButton onClick={handleClose}>
              <CloseIcon className="drawerSvg" />
            </IconButton>
          </Box>

          <BlogCategoryList />
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export { BlogCategoryTopBar };
