import React from "react";

import { Box, Typography } from "@mui/material";
import { DisplayProductFilter } from "dto/product/productfilter.dto";
import { DetailItem } from "../product/page/DetailItem";
import { CommonTools } from "tools/utils/common.tool";
import { logger } from "tools/utils/logger";

type Props = {
  item: DisplayProductFilter;
};

const ProductFilterItemValue: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    // <DetailItem
    //   identifier={CommonTools.processObjectField(item, ["filter", "_name"])}
    //   defaultOpen={true}
    // >
    //   <Typography>
    //     {CommonTools.processObjectField(item, ["filterValue"])}
    //   </Typography>
    // </DetailItem>
    <Box>
      <Typography className="filterTitleStyle">
        {CommonTools.processObjectField(item, ["filter", "_name"])}
      </Typography>
      <Typography className="filterValueTextBox">
        {CommonTools.processObjectField(item, ["filterValue"])}
      </Typography>
    </Box>
  );
};

export { ProductFilterItemValue };
