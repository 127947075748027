import React from "react";

import { Box } from "@mui/material";
import { useProduct } from "hooks/useProduct";
import { Image } from "components/elements/display/Image";
import { CommonTools } from "tools/utils/common.tool";
import { GalleryCarousel } from "./GalleryCarousel";
import { NoImage } from "components/elements/display/NoImage";
import { GalleryDto } from "dto/system/gallery.dto";
import { logger } from "tools/utils/logger";
import { useResponsive } from "hooks/useResponsive";

const ProductMedia: React.FC = () => {
  const { product } = useProduct();
  const { downMD, downSM } = useResponsive();
  if (!product) return null;
  if (!product._galleryDefault) return <NoImage />;
  if (!product._galleryActiveList) return null;

  const processItemSliderSeconde = (item: GalleryDto, index: number) => {
    if (!item) return null;
    return (
      <Box
        key={index}
        sx={{
          height: downSM ? "40vh" : downMD ? "50vh" : "100vh",
          width: "100%",
        }}>
        <Image
          key={index}
          url={CommonTools.processObjectField(item, ["cdnurl"])}
          typeMeasure="percentage"
          // sizeInPercentage={{ width: "100%", height: "90%" }}
          withLinK={false}
          style={{
            objectFit: "cover",
            width: "100%",
            // height: downSM ? "40vh" : downMD ? "50vh" : "100vh",
            height: "100%",
            maxHeight: "1200px",
          }}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      {product._galleryActiveList.map((photo, index) =>
        processItemSliderSeconde(photo, index)
      )}
    </Box>
  );

  // if (product._galleryActiveList.length === 1)
  //   return (
  //     <Box
  //       sx={{
  //         maxHeight: "844px",
  //         height: { md: "910px" },
  //         background: "red",
  //       }}>
  //       <Image
  //         url={CommonTools.processObjectField(product, [
  //           "_galleryDefault",
  //           "cdnurl",
  //         ])}
  //         typeMeasure="percentage"
  //         cutImageHeight={2000}
  //         cutImageWidth={2000}
  //         // sizeInPercentage={{ width: "100%", height: "90%" }}
  //         withLinK={false}
  //         style={{
  //           objectFit: "cover",
  //           width: "100%",
  //         }}
  //       />
  //     </Box>
  //   );
  // return (
  //   <Box>
  //     <GalleryCarousel
  //       objects={product._galleryActiveList}
  //       carouselStyle="primary"
  //     />
  //   </Box>
  // );
};

export { ProductMedia };
