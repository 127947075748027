import { Box } from "@mui/material";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { useEffect, useReducer, useRef } from "react";
import { Logo } from "components/general/Logo";
import { SearchBlock } from "components/header/SearchBlock";
import { CartBlock } from "components/header/CartBlock";
import { MenuBlock } from "components/header/MenuBlock";
import { ProfileBlock } from "components/header/ProfileBlock";
import { WishBlock } from "components/header/WishBlock";
import { CommonTools } from "tools/utils/common.tool";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUser } from "hooks/useUser";
import { useResponsive } from "hooks/useResponsive";

type State = {
  bgColor: string;
  shadow: string;
  svgColor: string;
  headerHeight: number;
  alignItems: string;
  justifyContent: string;
  logoHeight: number;
  logoWidth: number;
  logoWidthMobile: number;

  translateX: number;
  translateY: number;
};

type Action =
  | { type: "SET_BG_COLOR"; payload: string }
  | { type: "SET_SHADOW"; payload: string }
  | { type: "SET_SVG_COLOR"; payload: string }
  | {
      type: "SET_COMMON_DATA";
      payload: {
        headerHeight: number;
        alignItems: string;
        justifyContent: string;
        logoHeight: number;
        logoWidth: number;
        logoWidthMobile: number;
        translateX: number;
        translateY: number;
      };
    };

const initialState: State = {
  bgColor: "transparent",
  shadow: "none",
  svgColor: "white",
  headerHeight: 400,
  alignItems: "start",
  justifyContent: "space-between",
  logoHeight: 300,
  logoWidth: 498,
  logoWidthMobile: 332,
  translateX: 0,
  translateY: 0,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_BG_COLOR":
      return { ...state, bgColor: action.payload };
    case "SET_SHADOW":
      return { ...state, shadow: action.payload };
    case "SET_SVG_COLOR":
      return { ...state, svgColor: action.payload };
    case "SET_COMMON_DATA": {
      return {
        ...state,
        headerHeight: action.payload.headerHeight,
        alignItems: action.payload.alignItems,
        justifyContent: action.payload.justifyContent,
        logoHeight: action.payload.logoHeight,
        logoWidth: action.payload.logoWidth,
        logoWidthMobile: action.payload.logoWidthMobile,
        translateX: action.payload.translateX,
        translateY: action.payload.translateY,
      };
    }
    default:
      return state;
  }
}

const Header: React.FC<IPage> = ({ currentRoute }) => {
  const isHomePage =
    CommonTools.processObjectField(currentRoute, ["_mainobject"]) ===
    "homepage";

  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { user } = useUser();

  const [state, dispatch] = useReducer(reducer, initialState);
  const bgColorRef = useRef(state.bgColor);
  const { downXS } = useResponsive();

  useEffect(() => {
    bgColorRef.current = state.bgColor;
  }, [state.bgColor]);

  useEffect(() => {
    if (!isHomePage) return;
    const onScroll = () => {
      const currentBgColor = bgColorRef.current;
      const shouldChangeToWhite =
        window.scrollY > (isMobile ? 300 : 600) && currentBgColor !== "white";
      const shouldChangeToTransparent =
        window.scrollY <= (isMobile ? 300 : 600) &&
        currentBgColor !== "transparent";
      const screenWidth = window.innerWidth;
      if (shouldChangeToWhite) {
        setTimeout(() => {
          dispatch({
            type: "SET_BG_COLOR",
            payload: "white",
          });
          dispatch({
            type: "SET_SHADOW",
            payload: "0px 4px 4px rgba(0, 0, 0, 0.1)",
          });
          dispatch({
            type: "SET_SVG_COLOR",
            payload: "black",
          });
        }, 0);
      } else if (shouldChangeToTransparent) {
        dispatch({
          type: "SET_BG_COLOR",
          payload: "transparent",
        });
        dispatch({
          type: "SET_SHADOW",
          payload: "none",
        });
        dispatch({
          type: "SET_SVG_COLOR",
          payload: "white",
        });
      }
      dispatch({
        type: "SET_COMMON_DATA",
        payload: {
          headerHeight: window.scrollY > 0 ? 110 : 400,
          alignItems: window.scrollY > 0 ? "center" : "flex-start",
          justifyContent: window.scrollY > 0 ? "space-between" : "center",
          logoHeight: window.scrollY > 0 ? 90 : 300,
          logoWidth: window.scrollY > 0 ? 166 : 498,
          translateX:
            window.scrollY > 0 ? -(screenWidth / 2 - 163) : isMobile ? 0 : 0,
          translateY: window.scrollY > 0 ? 0 : 30,
          logoWidthMobile: window.scrollY > 0 ? 166 : 332,
        },
      });
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [isHomePage]);

  const handleScrollTop = () => {
    if (isHomePage) window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: isHomePage ? state.headerHeight : "110px",
        boxShadow: isHomePage ? state.shadow : "rgba(0, 0, 0, 0.1) 0px 4px 4px",
        position: "fixed",
        top: 0,
        zIndex: 100,
        backgroundColor: isHomePage ? state.bgColor : "white",
        transition: "height 0.5s ease",
        padding: 0.5,
      }}>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          justifyContent: "flex-start",
          alignItems: "center",
          position: "fixed",
          pl: 3,
          py: "37px",
          zIndex: 1,
        }}>
        <CartBlock colorSvg={isHomePage ? state.svgColor : "black"} />
      </Box>

      <Box
        sx={{
          display: "flex",
          px: { xs: 2, sm: 4 },
          height: "100%",
          transition: isHomePage ? "alignItems 0.5s ease" : "none",
        }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: isHomePage
              ? "center"
              : isMobile
              ? "center"
              : "flex-start",
            px: isHomePage ? 0 : { xs: 0, sm: 10 },
            transform:
              isHomePage && !isMobile
                ? `translate(${state.translateX}px, ${state.translateY}px)`
                : `translate(0, 0)`,
            transition: isHomePage ? "transform 0.5s ease" : "none",
            zIndex: 0,
          }}>
          <Logo
            width={
              isHomePage && !isMobile
                ? state.logoWidth
                : isMobile
                ? state.logoWidthMobile
                : 166
            }
            height={isHomePage ? state.logoHeight : 90}
            style={{
              transition: isHomePage
                ? "width 0.3s ease, height 0.3s ease"
                : isMobile
                ? "none"
                : "none",
              zIndex: 10,
            }}
            handleAction={isHomePage ? handleScrollTop : undefined}
          />
        </Box>

        <Box
          sx={{
            // width: isMobile ? "100%" : "auto",
            width: "auto",
            display: "flex",
            justifyContent: isMobile ? "flex-end" : "space-between",
            alignItems: "center",
            position: "fixed",
            right: isMobile ? 0 : 80,
            py: "37px",
            zIndex: 0,
          }}>
          <Box
            sx={{
              px: 1,
              display: isMobile ? "none" : "block",
            }}>
            <SearchBlock colorSvg={isHomePage ? state.svgColor : "black"} />
          </Box>
          <Box
            sx={{
              px: 1,
              pl: isMobile ? 6 : 0,
              display: isMobile ? "none" : "block",
            }}>
            <CartBlock colorSvg={isHomePage ? state.svgColor : "black"} />
          </Box>
          <Box sx={{ px: 1, display: isMobile ? "none" : "block" }}>
            <ProfileBlock colorSvg={isHomePage ? state.svgColor : "black"} />
          </Box>
          {user ? (
            <Box sx={{ px: 1, display: isMobile ? "none" : "block" }}>
              <WishBlock colorSvg={isHomePage ? state.svgColor : "black"} />
            </Box>
          ) : null}
          <Box sx={{ px: 1, pr: isMobile ? 3 : 0 }}>
            <MenuBlock colorSvg={isHomePage ? state.svgColor : "black"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { Header };
