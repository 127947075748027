import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { useProduct } from "hooks/useProduct";
import { useState, useEffect } from "react";
import { ProductFilterList } from "components/product/productfilter/ProductFilterList";
import { ButtonAddToCart } from "components/product/product/page/ButtonAddToCart";
import { DetailItem } from "components/product/product/page/DetailItem";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { IPage } from "interfaces/page.interface";
import { CommonTools } from "tools/utils/common.tool";
import { Typography } from "@mui/material";
import { logger } from "tools/utils/logger";
import { OfferBlock } from "components/product/product/page/OfferBlock";
import { useLabel } from "hooks/useLabel";
import { ButtonWishlist } from "components/product/productwishlist/ButtonWishlist";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ProductDialogProps = {
  open: boolean;
  // selectedValue: string;
  onClose: (value: string) => void;
  product: any;
};

const ProductDialog: React.FC<ProductDialogProps> = ({
  product,
  open,
  // selectedValue,
  onClose,
}) => {
  const { LL } = useLabel();
  const [selectedValue, setSelectedValue] = useState("");

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      aria-describedby="alert-dialog-slide-description"
      TransitionComponent={Transition}
      className="productDialog"
      sx={{}}>
      <DialogTitle
        className="detailsDialogTitle"
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          mt: 3,
        }}>
        <Typography sx={{ fontSize: "24px" }}>
          {CommonTools.processObjectField(product, ["_name"])}
        </Typography>
        {/* <ButtonWishlist
          idProduct={CommonTools.processObjectField(product, ["id"])}
        /> */}
        <Box className="drawerCloseButton">
          <IconButton onClick={handleClose}>
            <CloseIcon className="drawerSvg" />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box sx={{ px: 3, mb: 3 }}>
        {/* <Box sx={{}}>
          <OfferBlock />
        </Box> */}
        {/* <ProductFilterList />
        <Box sx={{ mt: 3 }}>
          <ButtonAddToCart
            object={product}
            onButtonClick={() => {
              onClose("add_to_cart");
            }}
          />
        </Box> */}
        <Box className="dialogBoxDescrpt" sx={{ mt: 3, width: "100%" }}>
          {/* <DetailItem identifier={LL("detail_product")} defaultOpen={true}>
            <HtmlTag
              content={CommonTools.processObjectField(product, [
                "_description",
              ])}
            />
          </DetailItem> */}
          {/* <Typography className="dialogBoxDescrptTitle">
            {LL("detail_product")}
          </Typography> */}
          <HtmlTag
            content={CommonTools.processObjectField(product, ["_description"])}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

type Props = { product: any };

const ProductDialogButton: React.FC<Props> = ({ product }) => {
  const [open, setOpen] = React.useState(false);
  const { LL } = useLabel();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  return (
    // <Box mt={3} className="authButton">
    //   <Box className="standardButtonSize whiteButton listItemButton">
    //     <Button
    //       className="buttonWithLoading flexJustifyCenter"
    //       onClick={handleClickOpen}>
    //       <Typography className="textButton" sx={{}}>
    //         {LL("moreDetails")}
    //       </Typography>
    //     </Button>
    //     <ProductDialog
    //       selectedValue={selectedValue}
    //       open={open}
    //       onClose={handleClose}
    //       product={product}
    //     />
    //   </Box>
    // </Box>
    <Box mt={0}>
      <Button
        className="productPageMoreInfo myButtonAnimation"
        onClick={handleClickOpen}>
        <Box className="textAnimation flexAlignRow">
          <Typography className="productDialogButtonStyle " sx={{}}>
            {LL("moreDetails")}
          </Typography>
          {/* <KeyboardArrowRightIcon className="iconSvg3" /> */}
        </Box>
      </Button>
      <ProductDialog open={open} onClose={handleClose} product={product} />
    </Box>
  );
};

export { ProductDialogButton };
