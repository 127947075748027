import React from "react";

import { Box, Container, Tab, Tabs, Typography } from "@mui/material";

import { IPage } from "interfaces/page.interface";
import { useUser } from "hooks/useUser";
import { useLabel } from "hooks/useLabel";
import { CommonTools } from "tools/utils/common.tool";
import { FormUser } from "components/user/profile/FormUser";
import { Config } from "tools/utils/config";

import { TabPanel } from "components/elements/display/TabPanel";
import { ChangePasswordForm } from "components/authentication/ChangePasswordForm";
import { UserOrderPage } from "./UserOrderPage";
import { UserWishlistPage } from "./UserWishlistPage";
import { useResource } from "hooks/useResource";

const ProfilePage: React.FC<IPage> = ({ currentRoute }) => {
  const { user, changePassword, changeProfile } = useUser();
  const { LL } = useLabel();
  const { tabValueUserProfile, handleChangeTabUserProfile } = useResource();
  // const [tabValue, setTabValue] = useState<string>(
  //   CommonTools.getAnchor(currentRoute, Config.DEFAULT_TAB)
  // );

  // const handleChangeTab = (event: React.SyntheticEvent, newValue: any) => {
  //   setTabValue(newValue);

  //   RouteTools.setHistory("/profile", {}, newValue);
  // };
  if (!user) return null;
  return (
    <Box className="authMaineBox">
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // background: "red",
          // height: "calc(100vh - 118px)",
          flexDirection: "column",
          px: 4,
        }}>
        <Box
          maxWidth="md"
          className="tabs"
          sx={{
            mt: 3,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <Tabs
            className="tabsSelector"
            variant="scrollable"
            value={tabValueUserProfile}
            onChange={handleChangeTabUserProfile}
            TabIndicatorProps={{
              style: {
                backgroundColor: "black",
                color: "black",
                height: "2px",
                border: "none",
              },
            }}>
            <Tab label={LL("Edit_profile")} value={"generalinfo"} />
            <Tab label={LL("Change_password")} value={"changepassword"} />
            <Tab label={LL("My_order")} value={"userorder"} />
            <Tab label={LL("Wishlist")} value={"userwishlist"} />
          </Tabs>
        </Box>
        <TabPanel value={tabValueUserProfile} index={"generalinfo"}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",

                pb: 6,
                pt: 3,
                borderRadius: 4,
              }}>
              <Box
                sx={{
                  maxWidth: "600px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "400px",
                  }}>
                  <Typography variant="h1">{LL("myProfile_title")}</Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "400px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    mt: 3,
                  }}>
                  <Typography sx={{ fontSize: "18px" }}>
                    {LL("Email")}:
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    {CommonTools.processObjectField(user, ["email"])}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <FormUser defaultObject={user} onSubmit={changeProfile} />
                </Box>
              </Box>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={tabValueUserProfile} index={"changepassword"}>
          <Box sx={{ width: "100%", height: "380px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                // px: { xs: 4, sm: 10 },
                pb: 6,
                pt: 3,
                borderRadius: 4,
              }}>
              <Box
                sx={{
                  maxWidth: "600px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    maxWidth: "400px",
                    justifyContent: "start",
                    alignItems: "center",
                  }}>
                  <Typography variant="h1">
                    {LL("changePassword_title")}
                  </Typography>
                </Box>
                <ChangePasswordForm onSubmit={changePassword} />
              </Box>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={tabValueUserProfile} index={"userorder"}>
          <Box sx={{ minHeight: "380px" }}>
            <UserOrderPage currentRoute={currentRoute} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValueUserProfile} index={"userwishlist"}>
          <Box sx={{ minHeight: "705px" }}>
            <UserWishlistPage currentRoute={currentRoute} />
          </Box>
        </TabPanel>
      </Container>
    </Box>
  );
};

export { ProfilePage };
