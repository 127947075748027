import React from "react";

import { FilterDictionarDto } from "dto/product/filterdictionar.dto";

import { Box, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";

import { MyButton } from "components/elements/button/MyButton";
import { Image } from "components/elements/display/Image";

type Props = {
  item: FilterDictionarDto;
  selected: boolean;
  onSelected: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ProductFilterDictionarItem: React.FC<Props> = ({
  item,
  selected,
  onSelected,
}) => {
  if (!item) return null;
  const id = CommonTools.processObjectField(item, ["id"]);

  const processGallery = () => {
    if (!item) return null;
    if (!item._galleryDefault) return null;
    return (
      <Box
        sx={{
          width: "15px",
          height: "15px",
          // mt: "2px",
          overflow: "hidden",
          // ml: "-4px",
          mr: "4px",
          borderRadius: "100%",
        }}>
        <Image
          url={CommonTools.processObjectField(item, [
            "_galleryDefault",
            "cdnurl",
          ])}
          withLinK={false}
          style={{ borderRadius: "0%" }}
          sizeInPx={{
            width: 15,
            height: 15,
          }}
        />
      </Box>
    );
  };

  if (!id) return null;
  return (
    // <FormControlLabel
    //   value={CommonTools.processObjectField(item, ["id"])}
    //   control={<Radio />}
    //   label={CommonTools.processObjectField(item, ["_name"])}
    // />

    // todo
    // <Box className={selected ? "optionsButtonSelected " : "optionsButton "}>
    //   <MyButton
    //     variant={selected ? "contained" : "outlined"}
    //     value={id}
    //     className="optionsButtonFilter"
    //     onClick={(event) => onSelected(event)}>
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}>
    //       {processGallery()}
    //       <Typography
    //         sx={{ fontSize: "12px", fontWeight: "600", width: "auto", px: 0 }}>
    //         {CommonTools.processObjectField(item, ["_name"])}
    //       </Typography>
    //     </Box>
    //   </MyButton>
    // </Box>

    <Box
      // className={selected ? "optionsButtonSelected " : "optionsButton "}
      className={
        selected
          ? "myButtonAnimationBlackFilterSelected"
          : "myButtonAnimationBlackFilter"
      }>
      <MyButton
        // variant={selected ? "contained" : "outlined"}
        value={id}
        className="optionsButtonFilter"
        onClick={(event) => onSelected(event)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
          }}>
          {processGallery()}
          <Typography className="textAnimation fontSizeFilterButton" sx={{}}>
            {CommonTools.processObjectField(item, ["_name"])}
          </Typography>
        </Box>
      </MyButton>
    </Box>
  );
};

export { ProductFilterDictionarItem };
